import { useState, useEffect, useRef, useCallback } from "react";
import NavBar from "../../common/navbar";
import { baseUrl, eblotUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import { Add, CopyableCell, GreenCheck, RedCross, formatDateToDDMMYYYY, formatDateToMMDDYYYY, getCurrentDateFormatted, getCurrentDateYYYYMMDD, getLastMonthLastDay } from "../../common/functions";
import axios from "axios";
import { useLocation } from "react-router-dom";
import EditTradeEblot from "./tools/editTradeEblot";
import AddTradeEblot from "./tools/addNewTrade";
import MatchedVcon from "./tools/matchedVcon";
import { CentralizedTrade } from "../../models/trades";

function ViewEBlot() {
  let [trades, setTrades] = useState<CentralizedTrade[]>([]);
  let [filteredTrades, setFilteredTrades] = useState<CentralizedTrade[]>([]);
  let [tradeInfoDisplayNotValidated, setTradeInfoDisplayNotValidated] = useState("none");
  let [tradeInfoDisplayValidated, setTradeInfoDisplayValidated] = useState("none");

  let [tradeAddInfoDisplay, setTradeAddInfoDisplay] = useState("none");
  let [matchedVconDisplay, setMatchVconDisplay] = useState("none");
  let unEditableParams = ["Id", "Updated Notional", "B/S", "BB Ticker", "Location", "Trade Date", "Trade Time", "Settle Date", "Price", "Notional Amount", "Settlement Amount", "Principal", "Triada Trade Id", "Seq No", "ISIN", "Currency", "Yield", "Accrued Interest", "Trade Type", "App Check Test", "Trade App Status", "Nomura Upload Status", "Broker Email Status", "App Check Test", "Front Office Check", "Trade Type", "Last Nomura Generated"];
  let unEditableUsed = ["Id"];
  const [pair, setPair] = useState(false);

  let [tickers, setTickers] = useState({});
  let [newIssues, setNewIssues] = useState([]);

  let [tradeInfo, setTradeInfo] = useState<any>({});
  // let [newTrade, setNewTrade] = useState<any>({ "Trade Date": getCurrentDateYYYYMMDD() });
  const [newTrade1, setNewTrade1] = useState({ "Trade Date": getCurrentDateYYYYMMDD(), "B/S": "B", Currency: "USD", "Primary (True/False)": "Secondary" });
  const [newTrade2, setNewTrade2] = useState({ "Trade Date": getCurrentDateYYYYMMDD(), "B/S": "S", Currency: "USD", "Primary (True/False)": "Secondary" });
  let [newEditedTrade, setNewEditedTrade] = useState<any>({});

  let [authStatus, setAuthStatus] = useState("");

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);

  const handleContextMenu = useCallback((event: any, trade: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setTradeInfo(trade);
    setNewEditedTrade({ ...trade });
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);
  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);

  let tableTitles: any = ["B/S", "BB Ticker", "Location", "Trade Date", "Trade Time", "Settle Date", "Price", "Notional Amount", "Settlement Amount", "ISIN", "Currency", "Yield", "Front Office Check", "Front Office Note", "App Check Test", "Accrued Interest", "Trade Type", "Principal", "Triada Trade Id", "Seq No", "Trade App Status", "Nomura Upload Status", "Last Nomura Generated", "Counter Party", "Broker Full Name & Account", "Broker Email", "Settlement Venue", "Primary (True/False)", "Broker Email Status"];
  let addNewTradeTitles: any = ["B/S", "BB Ticker", "Trade Date", "Settle Date", "Price", "Notional Amount", "Counter Party", "Currency", "ISIN", "Front Office Note"];

  let widerTitles = ["BB Ticker", "ISIN", "Notional Amount", "Settlement Amount", "Principal", "Triada Trade Id", "Trade Date", "Settle Date", "Nomura Upload Status", "Broker Email Status", "Price"];
  let extraWideTitles = ["Broker Email", "Broker Full Name & Account", "Trade App Status", "Front Office Check", "App Check Test", "Settlement Venue", "Last Nomura Generated", "Front Office Note"];

  const [request, setRequestStatus] = useState(true);
  let query = new URLSearchParams(useLocation().search);

  let today: any = getCurrentDateFormatted(new Date(query.get("today") || new Date().getTime() + 24 * 60 * 60 * 1000));
  let yesterday: any = getCurrentDateFormatted(new Date(query.get("yesterday") || new Date().getTime() - 24 * 60 * 60 * 1000));

  let [fromRange, setFromRange] = useState<any>(yesterday);
  let [toRange, setToRange] = useState<any>(today);
  let url: any = eblotUrl + `all-trades?from=${fromRange}&to=${toRange}`;

  useEffect(() => {
    setRequestStatus(true);
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setTrades(data.trades);
        setFilteredTrades(data.trades);
        setTickers(data.positionsInformation);
        setNewIssues(data.newIssues);
        setRequestStatus(false);
      });
  }, []);

  function onChangeTradeFilter(event: any) {
    setFromRange(new Date());
    setToRange(new Date());
    const searchTerms = event.target.value.toLowerCase().split(" ");

    // Step 1: Filter positions based on search terms
    let filteredTrades = trades.filter((trade: any) => {
      if (!trade) return false;

      // Check if each property exists and includes any of the search terms
      return searchTerms.every((searchTerm: string) => Object.keys(trade).some((key) => trade[key]?.toString().toLowerCase().includes(searchTerm)));
    });

    // Sorting the filtered trades based on Trade Date
    if (searchTerms.length == 1 && searchTerms[0] == "") {
      filteredTrades = trades;
    } else {
      filteredTrades.sort((previous: any, next: any) => new Date(next["Trade Date"]).getTime() - new Date(previous["Trade Date"]).getTime());
    }

    // Update the state with filtered trades
    setFilteredTrades(filteredTrades);
  }
  async function queryTrades(event: any) {
    setRequestStatus(true);

    url = eblotUrl + `all-trades?from=${fromRange}&to=${toRange}`;
    let action: any = await fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        if (data) {
          setTrades(data.trades);
          setFilteredTrades(data.trades);
        }
      });
    setRequestStatus(false);
  }
  async function queryTradesMonthToDate(event: any) {
    setRequestStatus(true);
    let fromRange = getCurrentDateFormatted(getLastMonthLastDay(new Date()));
    let toDate = getCurrentDateFormatted(new Date());
    url = eblotUrl + `all-trades?from=${fromRange}&to=${toDate}`;
    setFromRange(fromRange);
    setToRange(toDate);
    let action: any = await fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        if (data) {
          setTrades(data.trades);
          setFilteredTrades(data.trades);
        }
      });

    setRequestStatus(false);
  }
  async function handleEditNomuraStatus(event: any, trade: any) {
    if (trade["Trade App Status"] == "uploaded_to_app") {
      setRequestStatus(true);
      event.preventDefault();
      let test = trade["Nomura Upload Status"] == "uploaded" ? "not uploaded" : "uploaded";
      let tradeType = trade["Trade Type"] == "vcon" ? "vcons" : trade["Trade Type"];
      let data = { Id: trade["Id"], "Nomura Upload Status": test, tradeType: tradeType };

      try {
        let auth = await axios.post(baseUrl + "edit-trade?logs=false", data, postRequestOptions);

        if (!auth.data.error) {
          window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;
          setRequestStatus(false);
        } else {
          window.alert(auth.data.error);
          setRequestStatus(false);
        }
        setRequestStatus(false);
      } catch (error) {}
    } else {
      if (trade["Trade App Status"] == "new, inputted by front office") {
        window.alert("Trade was inputted by front office but does not exist in the app!");
      } else {
        window.alert("Trade was scraped in vcon inbox but does not exist in the app!");
      }
    }
  }
  async function handleEditBrokerStatus(event: any, trade: any) {
    if (trade["Trade App Status"] == "uploaded_to_app") {
      setRequestStatus(true);
      event.preventDefault();
      let test = trade["Broker Email Status"] == "sent" ? "not sent" : "sent";
      let tradeType = trade["Trade Type"] == "vcon" ? "vcons" : trade["Trade Type"];
      let data = { Id: trade["Id"], "Broker Email Status": test, tradeType: tradeType, "Broker Email": trade["Broker Email"], "Settlement Venue": trade["Settlement Venue"], "Triada-Broker Notes": trade["Triada-Broker Notes"], Trade: trade };

      try {
        let auth = await axios.post(eblotUrl + "send-broker-confirmation?logs=false", data, postRequestOptions);

        if (!auth.data.error) {
          window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;
          setRequestStatus(false);
        } else {
          window.alert(auth.data.error);
          setRequestStatus(false);
        }
        setRequestStatus(false);
      } catch (error) {}
    } else {
      if (trade["Trade App Status"] == "new, inputted by front office") {
        window.alert("Trade was inputted by front office but does not exist in the app!");
      } else {
        window.alert("Trade was scraped in vcon inbox but does not exist in the app!");
      }
    }
  }
  async function handleEditFrontOfficeStatus(event: any, trade: any) {
    if (trade["Trade Type"] == "written_blotter") {
      window.alert(`${trade["BB Ticker"]} Trade is not inputted in the app, you can delete/edit this trade`);
    } else {
      setRequestStatus(true);
      event.preventDefault();
      console.log({ trade });
      let test = trade["Front Office Check"] ? false : true;
      let tradeType = trade["Trade Type"] == "vcon" ? "vcons" : trade["Trade Type"];
      let data = { Id: trade["Id"], "Front Office Check": test, tradeType: tradeType };

      try {
        let auth = await axios.post(baseUrl + "edit-trade?logs=false", data, postRequestOptions);

        if (!auth.data.error) {
          window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;
          setRequestStatus(false);
        } else {
          window.alert(auth.data.error);
          setRequestStatus(false);
        }
        setRequestStatus(false);
      } catch (error) {}
    }
  }
  async function editMatchVcon(event: any, title: string, newValue: string) {
    setRequestStatus(true);
    event.preventDefault();
    tradeInfo[title] = newValue;
    try {
      let auth = await axios.post(eblotUrl + "edit-eblot-based-on-vcon", tradeInfo, postRequestOptions);

      if (!auth.data.error) {
        window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;
        setRequestStatus(false);
      } else {
        window.alert(auth.data.error);
        setRequestStatus(false);
      }
      setRequestStatus(false);
    } catch (error) {}
  }
  async function handleEditTrade(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    newEditedTrade["tradeType"] = tradeInfo["Trade Type"];

    let url = tradeInfo["Trade App Status"] == "new, inputted by front office" ? eblotUrl + "edit-new-trade?logs=false" : baseUrl + "edit-trade?logs=false&source=eblot";

    try {
      let auth: any = await axios.post(url, newEditedTrade, postRequestOptions);

      if (!auth.data.error) {
        window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;
      } else {
        setAuthStatus(auth.data.error);
        setRequestStatus(false);
      }
    } catch (error) {
      setAuthStatus("error");
    }
  }
  async function handleAddTrade(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = { corp: { ...newTrade1 }, tsy: { ...newTrade2 }, "o-r": pair };

    try {
      let auth: any = await axios.post(eblotUrl + "add-new-trade", formData, postRequestOptions);

      if (!auth.data.error) {
        window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;
      } else {
        setAuthStatus(auth.data.error);
        setRequestStatus(false);
      }
    } catch (error) {
      setAuthStatus("error");
    }
  }
  async function handleDeleteNewTrade(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData();

    // Append the date to the FormData object with the desired key
    formData.append("Triada Trade Id", tradeInfo["Triada Trade Id"]);
    let confirm = window.confirm("Confirm delete trade " + tradeInfo["BB Ticker"]);
    if (confirm) {
      try {
        let auth: any = await axios.post(eblotUrl + "delete-new-trade", formData, postRequestOptions);

        if (!auth.data.error) {
          window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;

          setRequestStatus(false);
        } else {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        }
      } catch (error) {
        setAuthStatus("error");
      }
    }
    setRequestStatus(false);
  }
  async function handleCancelVcon(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let confirm = window.prompt("Input reason why cancel vcon " + tradeInfo["BB Ticker"]);
    if (confirm) {
      try {
        tradeInfo["Front Office Note"] = confirm;
        let auth: any = await axios.post(eblotUrl + "cancel-vcon", tradeInfo, postRequestOptions);

        if (!auth.data.error) {
          window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;

          setRequestStatus(false);
        } else {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        }
      } catch (error) {
        setAuthStatus("error");
      }
    }
    setRequestStatus(false);
  }
  function cancelTradeInfo(event: any) {
    setTradeInfoDisplayNotValidated("none");
    setTradeInfoDisplayValidated("none");

    setTradeAddInfoDisplay("none");
    setMatchVconDisplay("none");

    setAuthStatus("");
  }
  async function handleResolveNewTrade(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData();

    // Append the date to the FormData object with the desired key
    formData.append("Triada Trade Id", tradeInfo["Triada Trade Id"]);
    let confirm = window.confirm("Confirm resolve trade " + tradeInfo["BB Ticker"]);
    if (confirm) {
      try {
        let auth: any = await axios.post(eblotUrl + "resolve-new-trade", formData, postRequestOptions);

        if (!auth.data.error) {
          window.location.href = `/?today=${toRange}&yesterday=${fromRange}`;

          setRequestStatus(false);
        } else {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        }
      } catch (error) {
        setAuthStatus("error");
      }
    }
    setRequestStatus(false);
  }

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else {
    return (
      <div>
        <NavBar />
        <div className="trades-inputs">
          <div className="col-4 search-container">
            <input
              className="form-control mr-sm-2 filter-trades-search-bar"
              type="search"
              placeholder="Search Trade By BB Ticker or ISIN"
              aria-label="Search"
              onChange={(event) => {
                onChangeTradeFilter(event);
              }}
            />
          </div>
          <div className="calender-input-trades-container">
            <div className="calender-inputs-container">
              <div className="edit-logs-time-picker-container">
                <p className="edit-logs-time-picker-text">From</p>
                <input title="date" type="date" className="input-calendar edit-logs-time-picker" value={fromRange} onChange={(event: any) => setFromRange(event.target.value)} />
              </div>
              <div className="edit-logs-time-picker-container">
                <p className="edit-logs-time-picker-text">To</p>
                <input title="date" type="date" className="input-calendar edit-logs-time-picker" value={toRange} onChange={(event: any) => setToRange(event.target.value)} />
              </div>
            </div>
            <button className="btn upload-btn no-flex query-trade-btn" onClick={(event) => queryTrades(event)}>
              Query
            </button>
            <button className="btn upload-btn no-flex query-trade-btn" onClick={(event) => queryTradesMonthToDate(event)}>
              MTD
            </button>
          </div>
        </div>

        <div
          style={{
            overflowX: "auto",
          }}
          className="table-container-custom"
          onClick={handleClick}
        >
          <table id="table-id" className="table table-hover table-portfolio table-trades eblot-table">
            <tbody>
              <tr className="sticky-top table-header">
                {tableTitles.map((title: string, index: number) => (
                  <td key={index} className={widerTitles.includes(title) ? "wider-table-cell" : extraWideTitles.includes(title) ? "extra-wider-table-cell" : ""} style={{ borderRight: index == 14 ? "solid grey 1px" : "" }}>
                    {title}
                  </td>
                ))}
              </tr>

              {filteredTrades.map((trade: any, index: number) => (
                <tr key={index} className="table-body" style={{ backgroundColor: trade["Trade App Status"] != "uploaded_to_app" ? "white" : "#E0E0E0" }} onContextMenu={(event) => handleContextMenu(event, trade)} onDoubleClick={(event) => handleContextMenu(event, trade)}>
                  {tableTitles.map((title: string, index: number) =>
                    title == "BB Ticker" ? (
                      <CopyableCell key={index} text={trade[title]} />
                    ) : title == "Nomura Upload Status" ? (
                      <td style={{ cursor: "pointer", position: "relative", backgroundColor: trade["Color"] }} onClick={(event) => handleEditNomuraStatus(event, trade)}>
                        {trade["Nomura Upload Status"] == "uploaded" ? <GreenCheck /> : <RedCross />}
                      </td>
                    ) : title == "Broker Email Status" ? (
                      <td style={{ cursor: "pointer", position: "relative", backgroundColor: trade["Color"] }} onClick={(event) => handleEditBrokerStatus(event, trade)}>
                        {trade["Broker Email Status"] == "sent" ? <GreenCheck /> : <RedCross />}
                      </td>
                    ) : title == "Trade App Status" ? (
                      <td style={{ cursor: "pointer", position: "relative", backgroundColor: trade["Color"] }}>{trade[title] == "uploaded_to_app" ? <GreenCheck /> : trade[title]}</td>
                    ) : title == "Front Office Check" ? (
                      <td style={{ cursor: "pointer", position: "relative", backgroundColor: trade["Color"] }} onClick={(event) => handleEditFrontOfficeStatus(event, trade)}>
                        {trade[title] ? <GreenCheck /> : <RedCross />}
                      </td>
                    ) : title == "B/S" ? (
                      <CopyableCell key={index} text={trade[title]} type="summary" color={trade[title + " Color"] ? trade[title + " Color"] : trade[title] == "B" ? "#81d697" : "#f28f99"} />
                    ) : title == "App Check Test" ? (
                      <CopyableCell key={index} text={trade[title]} type="summary" color={trade[title] == "Validated" ? "#81d697" : (trade[title] || "").toString().includes("Matches") ? "yellow" : "#f28f99"} borderRight={title == "App Check Test" ? true : false} />
                    ) : (
                      <CopyableCell key={index} text={isFinite(trade[title]) && trade[title] ? (Math.round(trade[title] * 10000) / 10000).toLocaleString() : trade[title]} type="summary" color={trade[title + " Color"] ? trade[title + " Color"] : null} />
                    )
                  )}
                </tr>
              ))}
              {contextMenuState.visible && (
                <div
                  ref={contextMenuRef}
                  style={{
                    position: "absolute",
                    top: contextMenuState.y,
                    left: contextMenuState.x,
                  }}
                  className="context-menu-container"
                >
                  <div className="context-menu">
                    <div className="context-menue-row">
                      <p
                        className="context-menu-text"
                        onClick={(event) => {
                          if (tradeInfo["Trade App Status"] === "new, inputted by front office") {
                            setTradeInfoDisplayNotValidated("block");
                          } else if (tradeInfo["Trade App Status"] === "new, found in vcon inbox") {
                            tradeInfo["Trade Date"] = getCurrentDateYYYYMMDD(tradeInfo["Trade Date"]);
                            tradeInfo["Settle Date"] = getCurrentDateYYYYMMDD(tradeInfo["Settle Date"]);

                            setNewTrade1(tradeInfo);

                            setTradeAddInfoDisplay("block");
                          } else {
                            setTradeInfoDisplayValidated("block");
                          }
                        }}
                      >
                        Edit Trade
                      </p>
                    </div>

                    {tradeInfo["Trade App Status"] == "new, inputted by front office" ? (
                      <>
                        {tradeInfo["App Check Test"].includes("Matches") ? (
                          <div className="context-menue-row">
                            <p className="context-menu-text" onClick={(event) => setMatchVconDisplay("block")}>
                              View Matched Vcon
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="context-menue-row">
                          <p className="context-menu-text" onClick={(event) => handleDeleteNewTrade(event)}>
                            Delete Trade
                          </p>
                        </div>
                        <hr className="hr" />
                        <div className="context-menue-row">
                          <p className="context-menu-text" onClick={(event) => handleResolveNewTrade(event)}>
                            Resolve Trade
                          </p>
                        </div>
                      </>
                    ) : tradeInfo["Trade App Status"] == "new, found in vcon inbox" ? (
                      <div className="context-menue-row">
                        <p
                          className="context-menu-text"
                          onClick={(event) => {
                            handleCancelVcon(event);
                          }}
                        >
                          Cancel Vcon
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </tbody>
          </table>
        </div>
        <div className="edit-info-container-1" style={{ display: tradeInfoDisplayValidated }}>
          <EditTradeEblot trade={newEditedTrade} authStatus={authStatus} unEditableParams={unEditableParams} editTableTitles={addNewTradeTitles} handleEditTrade={handleEditTrade} cancelTradeInfo={cancelTradeInfo} tickers={tickers} setNewEditedTrade={setNewEditedTrade} newIssues={newIssues} />
        </div>
        <div className="edit-info-container-1" style={{ display: tradeInfoDisplayNotValidated }}>
          <EditTradeEblot trade={newEditedTrade} authStatus={authStatus} unEditableParams={unEditableUsed} editTableTitles={addNewTradeTitles} handleEditTrade={handleEditTrade} cancelTradeInfo={cancelTradeInfo} tickers={tickers} setNewEditedTrade={setNewEditedTrade} newIssues={newIssues} />
        </div>
        <div className="edit-info-container-1" style={{ display: tradeAddInfoDisplay }}>
          {/* <AddTradeEblot authStatus={authStatus} unEditableParams={unEditableParams} editTableTitles={addNewTradeTitles} handleEditTrade={handleAddTrade} cancelTradeInfo={cancelTradeInfo} tickers={tickers} newTrade={newTrade} setNewTrade={setNewTrade} newIssues={newIssues} /> */}
          <AddTradeEblot authStatus={authStatus} unEditableParams={unEditableParams} editTableTitles={addNewTradeTitles} handleAddTrade={handleAddTrade} cancelTradeInfo={cancelTradeInfo} tickers={tickers} newTrade1={newTrade1} setNewTrade1={setNewTrade1} newTrade2={newTrade2} setNewTrade2={setNewTrade2} newIssues={newIssues} setPair={setPair} pair={pair} />
        </div>
        <div className="edit-info-container-1" style={{ display: matchedVconDisplay }}>
          <MatchedVcon trade={tradeInfo["Matched Vcon"] || {}} editTableTitles={addNewTradeTitles} setMatchVconDisplay={setMatchVconDisplay} foTrade={tradeInfo} editMatchVcon={editMatchVcon} />
        </div>
        <div onClick={(event) => setTradeAddInfoDisplay("block")}>
          <Add />
        </div>
      </div>
    );
  }
}
export default ViewEBlot;
