import PaginationArrows from "./historyNavBar";

function NavbarAdmin(props: any) {
  let newTrades = parseInt(localStorage.getItem("newTrades") || "0");

  return (
    <div>
      <nav className="navbar navbar-expand-lg  navbar-custom">
        <div className="container-fluid">
          <a onClick={(event) => props.navigate(event, "")}>
            <img src={"/photos/triada-logo.png"} className="header-logo" alt="logo-triada" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 header-items">
              <li className="nav-item dropdown">
                <a className="nav-link active header-item" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Formatter
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "fx-excel")}>
                      Generate-Formatted-FX-Excel
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "ib-excel")}>
                      Format-IB-Excel
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "emsx-excel")}>
                      Format-EMSX-Excel
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "mufg-fx-excel")}>
                      Format-FX-MUFG-Excel
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "nomura-excel")}>
                      Format-Nomura-Excel
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link active header-item" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  E-Blot{" "}
                  {newTrades > 0 ? (
                    <div className="notification">
                      <span className="badge" id="messageCount">
                        {newTrades}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={(event) => (window.location.href = "/")}>
                      View E-BLOT
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => (window.location.href = "/view-new-issues")}>
                      View New Issue Trades
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "centralized-blot")}>
                      Generate-Centralized-E-BLOT
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "mufg-excel")}>
                      Generate-MUFG-E-Blot
                    </a>
                  </li>{" "}
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "mufg-excel-cds")}>
                      Generate-MUFG-CDS-E-Blot
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "confirmation-excel")}>
                      Generate Confirmation Excel
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link active header-item" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Front Office
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "")}>
                      Summary
                    </a>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "view-exposure-summary")}>
                      Exposure Summary
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "view-risk-summary")}>
                      Risk Summary
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "view-performers")}>
                      Top/Worst Performers
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "analytics")}>
                      Analytics - Demo
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link active header-item" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Reports
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "view-portfolio")}>
                      Portfolio
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "view-risk-report")}>
                      Risk Report
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "view-fact-sheet")}>
                      Fact Sheet
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "view-trades")}>
                      Trades
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link active header-item" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Operations
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "check-mufg")}>
                      Check MUFG
                    </a>
                  </li>{" "}
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "check-nomura")}>
                      Check Nomura
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "edit-logs-corporate-events")}>
                      Edit Logs & Corporate Events
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "update-prices")}>
                      Update Prices
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "check-live-positions")}>
                      Check Live Prices
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "update-previous-prices")}>
                      Update Previous Prices
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "upload-trades")}>
                      Upload Trades
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "fx-position")}>
                      Add FX Position
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "fund-details")}>
                      Fund Details
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "view-fact-sheet-data")}>
                      Fact Sheet Data
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "links")}>
                      Links
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "cash-reconcile")}>
                      Cash Reconcile
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link active header-item" onClick={(event) => props.navigate(event, "media")} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Triada Media
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link active header-item" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Account
                </a>

                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "user")}>
                      My Account
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "users")}>
                      View Users
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "add-users")}>
                      Add Users
                    </a>
                  </li>

                  <li>
                    <button className="dropdown-item" aria-current="page" onClick={(event) => props.handleLogOut(event)}>
                      Log Out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <PaginationArrows />
    </div>
  );
}

export default NavbarAdmin;
