import { CloseIcon, getCurrentDateYYYYMMDD } from "../../../common/functions";
import { brokerEmails, dataBrokerFullNames, dataBrokerLabelsFormatted, dataBrokersLabels, settlementVenueBroker } from "./data";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
function EditTradeEblot(props: any) {
  let editTableTitles = [...props.editTableTitles, "Id"];
  let newIssuesNames: any = [];
  for (let index = 0; index < props.newIssues.length; index++) {
    let trade = props.newIssues[index];
    let label = trade["BB Ticker"] + ", uploaded on " + trade["Trade Date"];
    trade["Price"] = trade["Issue Price"];
    trade["Trade Date"] = getCurrentDateYYYYMMDD(trade["Trade Date"]);
    trade["Settle Date"] = getCurrentDateYYYYMMDD(trade["Settle Date"]);

    newIssuesNames.push({ name: trade["BB Ticker"], label: label, value: trade });
  }

  for (let position of props.tickers.tsy) {
    newIssuesNames.push({ name: position["BB Ticker"], label: position["BB Ticker"], value: { "BB Ticker": position["BB Ticker"], ISIN: position["ISIN"], Currency: position["Currency"] } });
  }
  for (let position of props.tickers.corp) {
    newIssuesNames.push({ name: position["BB Ticker"], label: position["BB Ticker"], value: { "BB Ticker": position["BB Ticker"], ISIN: position["ISIN"], Currency: position["Currency"] } });
  }
  let currencies = ["USD", "EUR", "AUD", "GBP", "YEN", "CNH"];
  let currenciesFormat: any = [];
  for (let index = 0; index < currencies.length; index++) {
    let currency = currencies[index];

    currenciesFormat.push({ label: currency, value: currency });
  }
  // Helper function to split array into chunks of specified size
  const chunkArray = (array: any[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const titleChunks = chunkArray(editTableTitles, 3);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    props.setNewEditedTrade((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: "30px", // Set minimum height for the control
      height: "30px", // Set exact height for the control
      width: "90%", // Set exact height for the control
      margin: "auto",

      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        minheight: "30px !important",
        height: "30px !important",
      },
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px", // Set height for the value container
      padding: "0 8px", // Adjust padding as necessary
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        height: "30px !important",
        padding: "0 4px",
      },
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0", // Adjust margin for the input
      padding: "0", // Ensure no padding for the input
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        margin: "0",
        padding: "0",
      },
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      // height: '30px', // Set height for the indicator separator
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        // height: "30px !important",
      },
    }),
    ClearIndicator: (provided: any, state: any) => ({
      ...provided,
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        height: "30px !important",
        width: "10px !important",
      },
    }),

    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px", // Set height for the indicators container
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        height: "30px !important",
        padding: "0px !important",
      },
    }),
    indicatorContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px", // Set height for the indicators container
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        height: "30px !important",
        padding: "0px !important",
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999, // Ensure the menu is above other elements
    }),
  };

  return (
    <div className="position-container edit-info-container-2 edit-info-container-3 table-edit-2">
      <div onClick={(event) => props.cancelTradeInfo(event)} className="close-icon-container-edit-position">
        <CloseIcon />
      </div>

      <form
        onSubmit={(event) => props.handleEditTrade(event)}
        style={{
          overflowX: "auto",
        }}
      >
        <table id="table-id" className="table table-hover table-portfolio table-trades table-edit">
          <tbody>
            {titleChunks.map((chunk, indexChunk) => (
              <tr key={indexChunk}>
                {chunk.map((title, index) => (
                  <React.Fragment key={index}>
                    <td className="medium-wide-table-cell">{title}</td>
                    <td className="medium-wide-table-cell">
                      {props.unEditableParams.includes(title) ? (
                        <input name={title} type="text" value={props.trade[title]} className="paramDisabled" readOnly />
                      ) : title === "Edit Note" ? (
                        <input type="text" name={title} placeholder={props.trade[title]} required onChange={handleChange} />
                      ) : title === "Primary (True/False)" ? (
                        <select name={title} className="ninety-width" value={props.trade[title]} onChange={handleChange}>
                          <option value="" selected disabled>
                            Select Trade Type
                          </option>
                          <option value={"Primary"}>Primary</option>
                          <option value={"Secondary"}>Secondary</option>
                        </select>
                      ) : title === "B/S" ? (
                        <select name={title} className="ninety-width" value={props.trade[title]} onChange={handleChange}>
                          <option value="B">B</option>
                          <option value="S">S</option>
                        </select>
                      ) : title === "Counter Party" ? (
                        <>
                          <CreatableSelect
                            className="basic-single"
                            classNamePrefix="select"
                            value={props.trade[title] ? { value: props.trade[title], label: props.trade[title] } : null}
                            name={title}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            options={dataBrokerLabelsFormatted}
                            styles={customStyles}
                            onChange={(event: { label: string; value: string } | any) => {
                              if (event) {
                                console.log({ event });
                                const updatedTrade = { ...props.trade, [title]: event.value };
                                props.setNewEditedTrade(updatedTrade);
                              } else {
                                const updatedTrade = { ...props.trade, [title]: "" };
                                props.setNewEditedTrade(updatedTrade);
                              }
                            }}
                          />
                        </>
                      ) : title.includes("Date") ? (
                        <input type="date" name={title} value={getCurrentDateYYYYMMDD(props.trade[title])} onChange={handleChange} />
                      ) : title === "Broker Full Name & Account" ? (
                        <>
                          <CreatableSelect
                            className="basic-single"
                            classNamePrefix="select"
                            name={title}
                            value={props.trade[title] ? { value: props.trade[title], label: props.trade[title] } : null}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            options={dataBrokerFullNames}
                            styles={customStyles}
                            onChange={(event: { label: string; value: string } | any) => {
                              if (event) {
                                const updatedTrade = { ...props.trade, [title]: event.value };
                                props.setNewEditedTrade(updatedTrade);
                              } else {
                                const updatedTrade = { ...props.trade, [title]: "" };
                                props.setNewEditedTrade(updatedTrade);
                              }
                            }}
                          />
                        </>
                      ) : title === "Settlement Venue" ? (
                        <>
                          <CreatableSelect
                            className="basic-single"
                            classNamePrefix="select"
                            name={title}
                            value={props.trade[title] ? { value: props.trade[title], label: props.trade[title] } : null}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            options={settlementVenueBroker}
                            styles={customStyles}
                            onChange={(event: { label: string; value: string } | any) => {
                              if (event) {
                                const updatedTrade = { ...props.trade, [title]: event.value };
                                props.setNewEditedTrade(updatedTrade);
                              } else {
                                const updatedTrade = { ...props.trade, [title]: "" };
                                props.setNewEditedTrade(updatedTrade);
                              }
                            }}
                          />
                        </>
                      ) : title === "Broker Email" ? (
                        <>
                          <CreatableSelect
                            className="basic-single"
                            classNamePrefix="select"
                            name={title}
                            value={props.trade[title] ? { value: props.trade[title], label: props.trade[title] } : null}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            options={brokerEmails}
                            styles={customStyles}
                            onChange={(event: { label: string; value: string } | any) => {
                              console.log({ event });
                              if (event) {
                                const updatedTrade = { ...props.trade, [title]: event.value };
                                props.setNewEditedTrade(updatedTrade);
                              } else {
                                const updatedTrade = { ...props.trade, [title]: "" };
                                props.setNewEditedTrade(updatedTrade);
                              }
                            }}
                          />
                        </>
                      ) : title === "BB Ticker" ? (
                        <>
                          <CreatableSelect
                            className="basic-single"
                            classNamePrefix="select"
                            name={title}
                            isDisabled={isDisabled}
                            value={props.trade[title] ? { value: props.trade[title], label: props.trade[title] } : null}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            options={newIssuesNames}
                            styles={customStyles}
                            onChange={(event: { label: string; value: string } | any) => {
                              if (event) {
                                const selectedValue = event.name || event.label;
                                const selectedTrade = newIssuesNames.find((trade: any) => trade.name == selectedValue);

                                let temp: any = { ...props.trade };
                                if (selectedTrade) {
                                  for (let key in selectedTrade.value) {
                                    if (key != "id") {
                                      temp[key] = selectedTrade.value[key];
                                    }
                                  }
                                  props.setNewEditedTrade(temp);
                                } else {
                                  const updatedTrade = { ...props.trade, [title]: selectedValue };
                                  props.setNewEditedTrade(updatedTrade);
                                }
                              } else {
                                const updatedTrade = { ...props.trade, [title]: "" };
                                props.setNewEditedTrade(updatedTrade);
                              }
                            }}
                          />
                        </>
                      ) : title.includes("Amount") ? (
                        <input
                          type="text"
                          name={title}
                          placeholder={props.trade[title]}
                          onChange={(event) => {
                            let valuePut: any = event.target.value.toString().toLowerCase();
                            if (valuePut.includes("m")) {
                              valuePut = parseFloat(valuePut.replace(/m/g, ""));
                              valuePut = valuePut * 1000000;
                            } else if (valuePut.includes("k")) {
                              valuePut = parseFloat(valuePut.replace(/k/g, ""));
                              valuePut = valuePut * 1000;
                            }
                            const updatedTrade = { ...props.trade, [title]: valuePut.toLocaleString() };
                            props.setNewEditedTrade(updatedTrade);
                          }}
                          value={props.trade[title]}
                        />
                      ) : title == "Currency" ? (
                        <CreatableSelect
                          className="basic-single"
                          classNamePrefix="select"
                          value={props.trade[title] ? { value: props.trade[title], label: props.trade[title] } : null}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          options={currenciesFormat}
                          styles={customStyles}
                          onChange={(event: { label: string; value: string } | any) => {
                            if (event) {
                              const updatedTrade = { ...props.trade, [title]: event.value };
                              props.setNewEditedTrade(updatedTrade);
                            } else {
                              const updatedTrade = { ...props.trade, [title]: "" };
                              props.setNewEditedTrade(updatedTrade);
                            }
                          }}
                        />
                      ) : (
                        <input type="text" name={title} placeholder={props.trade[title]} onChange={handleChange} value={props.trade[title]} />
                      )}
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
            <tr>
              {props.trade["Treasury & Spread"] ? <td> Treasury Reference </td> : ""}

              {props.trade["Treasury & Spread"] ? <td> {props.trade["Treasury & Spread"]} </td> : ""}
            </tr>
          </tbody>
        </table>
        <div className="edit-info-edit-container">
          <button
            type="button"
            onClick={(event) => props.handleEditTrade(event)}
            style={{
              overflowX: "auto",
            }}
            className="btn upload-btn position-info-edit"
          >
            Edit Trade
          </button>
        </div>
      </form>
      {props.authStatus && <h4 className="error error-position-info">{props.authStatus}</h4>}
    </div>
  );
}

export default EditTradeEblot;
