export const brokers = {
  "13309": "BANK OF CHINA HONG KONG LIMITED",
  "31301": "STANDARD CHARTERED BANK LONDON",
  "55707": "FIRST ABU DHABI BNK PJSC- PB BROKER",
  "90997": "NIP DONE WITH BROKER",
  ABC: "ABCI SECURITIES COMPANY LTD",
  AHCL: "ADMIRALTY HARBOUR CAP LIMITED",
  ANZH: "ANZ BANKING GROUP LTD HONG KONG",
  ANZM: "ANZ BANKING GROUP LTD MELBOURNE",
  ARQA: "ARQAAM CAPITAL LTD- DXB",
  AXIS: "AXIS BANK LTD SINGAPORE BRANCH -SIN",
  BABC: "ARAB BANKING CORPORATION BAHRAIN",
  BARC: "BARCLAYS BANK PLC LONDON",
  BARL: "BARCLAYS BANK PLC LONDON 1",
  BCIN: "BARCLAYS CAPITAL INC 7256",
  BCOM: "BANK OF COMMUNICATION",
  BCPS: "BCP SECURITIES LLC  USA",
  "BNP CE": "BNP PARIBAS HONGKONG",
  BNPL: "BNP PARIBAS LONDON FI",
  BNPP: "BNP PARIBAS C+IB PARIS",
  BOC: "BANK OF CHINA HONG KONG LIMITED",
  "BOC 01": "BANK OF CHINA HONG KONG LTD-HK",
  "BOC 13309": "BANK OF CHINA (PB BROKER ONLY)",
  BOC25985: "BANK OF CHINA HONG KONG BRANCH",
  BOCB: "BANK OF CHINA BEIJING",
  BOCH: "BANK OF CHINA HONG KONG BRANCH",
  BOCHK: "BANK OF CHINA HONG KONG LIMITED",
  BOCI: "BANK OF CHINA INTL LONDON",
  BOCOM: "BANK OF COMMUNICATION",
  BONY: "BANK OF NEW YORK EC 18026",
  BOSC: "BOSCI - TP BALANCED FUND SP",
  "BRC 21057": "BARCLAYS DTC 0229",
  BSIL: "BOCOM INTERNATIONAL SECS EC47765",
  BTIG: "BTIG FIXED INCOME",
  CCBS: "CCB INTERNATIONAL SECU LTD - HKG",
  CEBH: "CHINA EVERBRIGHT SEC HK LTD-HKG 1",
  CEBI: "CEBI FINCL PRDTS LTD 79157",
  CEDEL22205: "WELLS FARGO BANK NA2",
  CICC: "CHINA INTERNATIONAL CAPITAL CORP HK",
  CIMB: "CIMB BANK BERHAD",
  CISB: "CHINA INVESTMENT SECS INTERNATIONAL",
  CITI: "CITIGROUP GLOBAL MARKETS INC",
  "CITI DTC 0908": "CITIGROUP GLBL MRKT LTD",
  CITIC28789: "CITIC SC BRKG HK LTD",
  CITIC29121: "CITIC SC BRKG HK LTD",
  CMBI: "CMB INTERNATIONAL SECURITIES LTD",
  CMS: "CHINA MERCHANTS SECURITS HK CO",
  "CMS EC 18665": "CHINA MERCHANTS SECS",
  CMSH: "CHINA MERCHANTS SECS CEDEL 18705",
  CNCB: "CHINA CITIC BANK INTERNATIONAL LTD",
  CNCBI21788: "CHINA CITIC BANK INTERNATIONAL LTD",
  CONV: "NOMURA PRIME SWITCH(NON CREST)",
  CRAG: "CREDIT AGRICOLE CIB PARIS-CDL 70496",
  CRIC: "CHINA CITIC BANK INTERNATIONAL LIMI",
  CRIE: "CHINA CITIC BANK INTERNATIONAL LTD",
  CS: "CREDIT SUISSE INTERNATIONAL",
  CSCI: "CHINA SECS INTL BROKERAGE CO LTD",
  CSUS: "CREDIT SUISSE SECURITIES LLC USA",
  CTIC: "CITIC SC BRKG HK LTD",
  CVSL: "BANK OF CHINA (PB BROKER ONLY)",
  CWLB: "WING LUNG BANK LIMITED HONG KONG",
  CWSI: "WING LUNG BANK LIMITED HONG KONG",
  DAIW: "DAIWA CAPITAL MARKETS HK EC 98891",
  DAIWA1: "DAIWA CAPTL MKTS HK LTD",
  DB: "DEUTSCHE BANK AG LDN EC91255",
  DBS: "DBS BANK SINGAPORE",
  DBSH: "DBS BANK HK LIMITED-HONG KONG",
  EBSI: "CHINA EVERBRIGHT SEC HK LTD-HKG 1",
  EIB: "EMIRATES INVESTMENT BANK",
  EMNBD: "EMIRATES NBD BANK PJSC",
  ENBD: "EMIRATES NBD PJSC SINGAPORE",
  ENDD: "EMIRATES NBD BANK PJSC",
  GFI: "GFI SECURITIES LIMITED SC EC",
  GS: "GOLDMAN SACHS INTL",
  GTJA: "GUOTAI JUNAN SECURITIES (HK)",
  HAIT: "HAITONG INTERNATIONAL SECURITIES CO",
  HSBC: "HONG KONG AND SHANGHAI - HKG",
  HUAT: "HUATAI FINANCIAL HOLDINGS HK LTD",
  HUATAI: "HUATAI FINANCIAL HOLDINGS HK LTD",
  ICBA: "ICBC ASIA LIMITED",
  ICBC: "ICBC STANDARD BANK PLC LONDON",
  ICBC20563: "ICBC INT SEC LTD CEDEL 20563",
  ICBI: "ICBC INT SEC LTD CEDEL 20563",
  ICBI21736: "ICBC INTERNATIONAL SECURITIES LTD",
  ING: "ING BANK NV",
  "ING UID-INZ": "ING BANK NV",
  IPL: "INSTINET PACIFIC LIMITED HKG",
  JEFF: "JEFFERIES INTERNATIONAL LTD LDN",
  JPML: "JP MORGAN MARKETS LIMITED LDN1",
  JPMS: "JP MORGAN SECURITIES LITD LDN LDSL",
  KDB: "KDB ASIA LIMITED",
  KGI: "KGI ASIA LIMITED - HONG KONG",
  KOTA: "KOTAK MAHINDRA UK LTD LDN",
  LIFI: "LIQUIDITY FINANCE LLP-LDN",
  LINE: "LINEAR INVESTMENTS LTD 41048",
  MACL: "MARKETAXESS CAP LTD",
  MADW: "MIRAE ASSET DAEWOO CO LTD",
  MAND: "MANDIRI SECURITIES PTE LTD -SIN",
  MIZU: "MIZUHO SECURITIES ASIA LTD HKG",
  MIZUX: "MIZUHO SECURITIES ASIA LTD HKG",
  "ML 15366": "MERRILL LYNCH INT EC 15366",
  MLI: "MERRILL LYNCH INTL LDN EC 92707",
  MLPF: "MERRILL LYNCH PIERCE FENNER",
  MS: "MORGAN STANLEY PLC LDN EC98366",
  MTBS: "MITSUBISHI UFJ SEC INT PLC LDN",
  MUTI: "MITSUBISHI UFJ TRUST INTL LTD LDN",
  NAB: "NATIONAL AUSTRALIA BANK LTD LONDON",
  NABB: "NATIONAL AUSTRALIA BK HONG KONG BR",
  NABME: "NAB MELBOURNE FI EC40242",
  NATI: "NATIXIS PARIS",
  NATW: "NATWEST MARKETS PLC",
  NBAD: "NAT BNK OF ABUDHABI UAE",
  NIKO: "SMBC NIKKO CAPITAL MARKETS LTD",
  NMR: "NIP DONE WITH BROKER",
  NORB: "NOOR ISLAMIC BANK-DUB",
  OCBC: "OVERSEA CHINESE BKING CORP LTD-SING",
  OCTO: "OCTO FINANCES SA",
  OISS: "ORBIT INVT SEC SERV LTD",
  OPPH: "OPPENHEIMER AND CO INC NYK",
  PARE: "BANK OF COMMUNICATIONS CO LTD",
  PATO: "PARETO SECURITIES AS - OSLO",
  RBCC: "RBC CAPITAL MARKETS LLC",
  RENA: "RENAISSANCE SEC CYPRUS LIMITED",
  "SC 73378": "STANDARD CHARTERED BANK HK CS 73378",
  "SC LOWY": "SC LOWY FINANCIAL HK LTD - NIP PB",
  SCB: "STANDARD CHARTERED BANK HONG KONG",
  SCBL: "STANDARD CHARTERED BANK LONDON",
  SCBS: "STANDARD CHARTERED BANK SINGAPORE",
  SCLO: "SC LOWY FINANCIAL HK LTD - NIP PB",
  SEBA: "STIFEL EUROPE BANK",
  SEEC: "STIFEL EUROPE BANK",
  SG: "SOCIETE GENERALE PARIS FI",
  SGX: "SGX BOND TRADING PTE LTD",
  SHUA: "SHUAA CAPITAL PSC",
  SPDB: "SHANGHAI PUDONG DEV BK CO LTD HK BR",
  SPDB17055: "SHANGHAI PUDONG DEV BK CO LTD HK BR",
  SPDB22136: "SHANGHAI PUDONG DEVELOPMENT BK",
  SPDI: "SPDB INTL INV MGMT LTD -HKG",
  SPEL: "THE SEAPORT GROUP EUROPE LLP LON",
  SSIB: "SOOCHOW SECURITIES INTL BRK LD -CEN",
  SUNG: "SUN GLOBAL INVESTMENT LIMITED",
  SWS: "SHENWAN HONGYUAN SECS H K LTD",
  TFI: "BANK OF CHINA HONG KONG LIMITED",
  TFIG: "CHN MINSHENG BANK CORP LTD CE 17687",
  TFIS: "BANK OF CHINA HONG KONG LIMITED",
  THSL: "CHINA TONGHAI SECURITIES LIMITED",
  "UBS LTD 18990": "UBS LIMITED CEDEL 18990",
  UBSA: "UBS AG LDN CEDEL 18800",
  UBSL: "UBS LIMITED",
  UBSS: "UBS SECURITIES ASIA LIMITED",
  UOBL: "UNITED OVERSEAS BK LTD SIN FID",
  VCL: "BANK OF CHINA HONG KONG LIMITED",
  VCM: "VANTAGE CAPITAL MARKETS LLP LONDON",
  WELF: "WELLS FARGO SECURITIES LLC USA",
  XYZQ: "INDUSTRIAL SECURITIES HONG KONG",
  YXSS: "STANDARD CHARTERED BANK HONG KONG",
  ZTFP: "ZHONGTAI INT FINANCIAL PRODUCTS",
};

export let dataBrokersLabels = [
  "13309 - BANK OF CHINA HONG KONG LIMITED",
  "31301 - STANDARD CHARTERED BANK LONDON",
  "55707 - FIRST ABU DHABI BNK PJSC- PB BROKER",
  "90997 - NIP DONE WITH BROKER",
  "ABC - ABCI SECURITIES COMPANY LTD",
  "AHCL - ADMIRALTY HARBOUR CAP LIMITED",
  "ANZH - ANZ BANKING GROUP LTD HONG KONG",
  "ANZM - ANZ BANKING GROUP LTD MELBOURNE",
  "ARQA - ARQAAM CAPITAL LTD- DXB",
  "AXIS - AXIS BANK LTD SINGAPORE BRANCH -SIN",
  "BABC - ARAB BANKING CORPORATION BAHRAIN",
  "BARC - BARCLAYS BANK PLC LONDON",
  "BARL - BARCLAYS BANK PLC LONDON 1",
  "BCIN - BARCLAYS CAPITAL INC 7256",
  "BCOM - BANK OF COMMUNICATION",
  "BCPS - BCP SECURITIES LLC  USA",
  "BNP CE - BNP PARIBAS HONGKONG",
  "BNPL - BNP PARIBAS LONDON FI",
  "BNPP - BNP PARIBAS C+IB PARIS",
  "BOC - BANK OF CHINA HONG KONG LIMITED",
  "BOC 01 - BANK OF CHINA HONG KONG LTD-HK",
  "BOC 13309 - BANK OF CHINA (PB BROKER ONLY)",
  "BOC25985 - BANK OF CHINA HONG KONG BRANCH",
  "BOCB - BANK OF CHINA BEIJING",
  "BOCH - BANK OF CHINA HONG KONG BRANCH",
  "BOCHK - BANK OF CHINA HONG KONG LIMITED",
  "BOCI - BANK OF CHINA INTL LONDON",
  "BOCOM - BANK OF COMMUNICATION",
  "BONY - BANK OF NEW YORK EC 18026",
  "BOSC - BOSCI - TP BALANCED FUND SP",
  "BRC 21057 - BARCLAYS DTC 0229",
  "BSIL - BOCOM INTERNATIONAL SECS EC47765",
  "BTIG - BTIG FIXED INCOME",
  "CCBS - CCB INTERNATIONAL SECU LTD - HKG",
  "CEBH - CHINA EVERBRIGHT SEC HK LTD-HKG 1",
  "CEBI - CEBI FINCL PRDTS LTD 79157",
  "CEDEL22205 - WELLS FARGO BANK NA2",
  "CICC - CHINA INTERNATIONAL CAPITAL CORP HK",
  "CIMB - CIMB BANK BERHAD",
  "CISB - CHINA INVESTMENT SECS INTERNATIONAL",
  "CITI - CITIGROUP GLOBAL MARKETS INC",
  "CITI DTC 0908 - CITIGROUP GLBL MRKT LTD",
  "CITIC28789 - CITIC SC BRKG HK LTD",
  "CITIC29121 - CITIC SC BRKG HK LTD",
  "CMBI - CMB INTERNATIONAL SECURITIES LTD",
  "CMS - CHINA MERCHANTS SECURITS HK CO",
  "CMS EC 18665 - CHINA MERCHANTS SECS",
  "CMSH - CHINA MERCHANTS SECS CEDEL 18705",
  "CNCB - CHINA CITIC BANK INTERNATIONAL LTD",
  "CNCBI21788 - CHINA CITIC BANK INTERNATIONAL LTD",
  "CONV - NOMURA PRIME SWITCH(NON CREST)",
  "CRAG - CREDIT AGRICOLE CIB PARIS-CDL 70496",
  "CRIC - CHINA CITIC BANK INTERNATIONAL LIMI",
  "CRIE - CHINA CITIC BANK INTERNATIONAL LTD",
  "CS - CREDIT SUISSE INTERNATIONAL",
  "CSCI - CHINA SECS INTL BROKERAGE CO LTD",
  "CSUS - CREDIT SUISSE SECURITIES LLC USA",
  "CTIC - CITIC SC BRKG HK LTD",
  "CVSL - BANK OF CHINA (PB BROKER ONLY)",
  "CWLB - WING LUNG BANK LIMITED HONG KONG",
  "CWSI - WING LUNG BANK LIMITED HONG KONG",
  "DAIW - DAIWA CAPITAL MARKETS HK EC 98891",
  "DAIWA1 - DAIWA CAPTL MKTS HK LTD",
  "DB - DEUTSCHE BANK AG LDN EC91255",
  "DBS - DBS BANK SINGAPORE",
  "DBSH - DBS BANK HK LIMITED-HONG KONG",
  "EBSI - CHINA EVERBRIGHT SEC HK LTD-HKG 1",
  "EIB - EMIRATES INVESTMENT BANK",
  "EMNBD - EMIRATES NBD BANK PJSC",
  "ENBD - EMIRATES NBD PJSC SINGAPORE",
  "ENDD - EMIRATES NBD BANK PJSC",
  "GFI - GFI SECURITIES LIMITED SC EC",
  "GS - GOLDMAN SACHS INTL",
  "GTJA - GUOTAI JUNAN SECURITIES (HK)",
  "HAIT - HAITONG INTERNATIONAL SECURITIES CO",
  "HSBC - HONG KONG AND SHANGHAI - HKG",
  "HUAT - HUATAI FINANCIAL HOLDINGS HK LTD",
  "HUATAI - HUATAI FINANCIAL HOLDINGS HK LTD",
  "ICBA - ICBC ASIA LIMITED",
  "ICBC - ICBC STANDARD BANK PLC LONDON",
  "ICBC20563 - ICBC INT SEC LTD CEDEL 20563",
  "ICBI - ICBC INT SEC LTD CEDEL 20563",
  "ICBI21736 - ICBC INTERNATIONAL SECURITIES LTD",
  "ING - ING BANK NV",
  "ING UID-INZ - ING BANK NV",
  "IPL - INSTINET PACIFIC LIMITED HKG",
  "JEFF - JEFFERIES INTERNATIONAL LTD LDN",
  "JPML - JP MORGAN MARKETS LIMITED LDN1",
  "JPMS - JP MORGAN SECURITIES LITD LDN LDSL",
  "KDB - KDB ASIA LIMITED",
  "KGI - KGI ASIA LIMITED - HONG KONG",
  "KOTA - KOTAK MAHINDRA UK LTD LDN",
  "LIFI - LIQUIDITY FINANCE LLP-LDN",
  "LINE - LINEAR INVESTMENTS LTD 41048",
  "MACL - MARKETAXESS CAP LTD",
  "MADW - MIRAE ASSET DAEWOO CO LTD",
  "MAND - MANDIRI SECURITIES PTE LTD -SIN",
  "MIZU - MIZUHO SECURITIES ASIA LTD HKG",
  "MIZUX - MIZUHO SECURITIES ASIA LTD HKG",
  "ML 15366 - MERRILL LYNCH INT EC 15366",
  "MLI - MERRILL LYNCH INTL LDN EC 92707",
  "MLPF - MERRILL LYNCH PIERCE FENNER",
  "MS - MORGAN STANLEY PLC LDN EC98366",
  "MTBS - MITSUBISHI UFJ SEC INT PLC LDN",
  "MUTI - MITSUBISHI UFJ TRUST INTL LTD LDN",
  "NAB - NATIONAL AUSTRALIA BANK LTD LONDON",
  "NABB - NATIONAL AUSTRALIA BK HONG KONG BR",
  "NABME - NAB MELBOURNE FI EC40242",
  "NATI - NATIXIS PARIS",
  "NATW - NATWEST MARKETS PLC",
  "NBAD - NAT BNK OF ABUDHABI UAE",
  "NIKO - SMBC NIKKO CAPITAL MARKETS LTD",
  "NMR - NIP DONE WITH BROKER",
  "NORB - NOOR ISLAMIC BANK-DUB",
  "OCBC - OVERSEA CHINESE BKING CORP LTD-SING",
  "OCTO - OCTO FINANCES SA",
  "OISS - ORBIT INVT SEC SERV LTD",
  "OPPH - OPPENHEIMER AND CO INC NYK",
  "PARE - BANK OF COMMUNICATIONS CO LTD",
  "PATO - PARETO SECURITIES AS - OSLO",
  "RBCC - RBC CAPITAL MARKETS LLC",
  "RENA - RENAISSANCE SEC CYPRUS LIMITED",
  "SC 73378 - STANDARD CHARTERED BANK HK CS 73378",
  "SC LOWY - SC LOWY FINANCIAL HK LTD - NIP PB",
  "SCB - STANDARD CHARTERED BANK HONG KONG",
  "SCBL - STANDARD CHARTERED BANK LONDON",
  "SCBS - STANDARD CHARTERED BANK SINGAPORE",
  "SCLO - SC LOWY FINANCIAL HK LTD - NIP PB",
  "SEBA - STIFEL EUROPE BANK",
  "SEEC - STIFEL EUROPE BANK",
  "SG - SOCIETE GENERALE PARIS FI",
  "SGX - SGX BOND TRADING PTE LTD",
  "SHUA - SHUAA CAPITAL PSC",
  "SPDB - SHANGHAI PUDONG DEV BK CO LTD HK BR",
  "SPDB17055 - SHANGHAI PUDONG DEV BK CO LTD HK BR",
  "SPDB22136 - SHANGHAI PUDONG DEVELOPMENT BK",
  "SPDI - SPDB INTL INV MGMT LTD -HKG",
  "SPEL - THE SEAPORT GROUP EUROPE LLP LON",
  "SSIB - SOOCHOW SECURITIES INTL BRK LD -CEN",
  "SUNG - SUN GLOBAL INVESTMENT LIMITED",
  "SWS - SHENWAN HONGYUAN SECS H K LTD",
  "TFI - BANK OF CHINA HONG KONG LIMITED",
  "TFIG - CHN MINSHENG BANK CORP LTD CE 17687",
  "TFIS - BANK OF CHINA HONG KONG LIMITED",
  "THSL - CHINA TONGHAI SECURITIES LIMITED",
  "UBS LTD 18990 - UBS LIMITED CEDEL 18990",
  "UBSA - UBS AG LDN CEDEL 18800",
  "UBSL - UBS LIMITED",
  "UBSS - UBS SECURITIES ASIA LIMITED",
  "UOBL - UNITED OVERSEAS BK LTD SIN FID",
  "VCL - BANK OF CHINA HONG KONG LIMITED",
  "VCM - VANTAGE CAPITAL MARKETS LLP LONDON",
  "WELF - WELLS FARGO SECURITIES LLC USA",
  "XYZQ - INDUSTRIAL SECURITIES HONG KONG",
  "YXSS - STANDARD CHARTERED BANK HONG KONG",
  "ZTFP - ZHONGTAI INT FINANCIAL PRODUCTS",
];

export let dataBrokerLabelsFormatted = [
  {
    value: "13309 - BANK OF CHINA HONG KONG LIMITED",
    label: "13309 - BANK OF CHINA HONG KONG LIMITED",
  },
  {
    value: "31301 - STANDARD CHARTERED BANK LONDON",
    label: "31301 - STANDARD CHARTERED BANK LONDON",
  },
  {
    value: "55707 - FIRST ABU DHABI BNK PJSC- PB BROKER",
    label: "55707 - FIRST ABU DHABI BNK PJSC- PB BROKER",
  },
  {
    value: "90997 - NIP DONE WITH BROKER",
    label: "90997 - NIP DONE WITH BROKER",
  },
  {
    value: "ABC - ABCI SECURITIES COMPANY LTD",
    label: "ABC - ABCI SECURITIES COMPANY LTD",
  },
  {
    value: "AHCL - ADMIRALTY HARBOUR CAP LIMITED",
    label: "AHCL - ADMIRALTY HARBOUR CAP LIMITED",
  },
  {
    value: "ANZH - ANZ BANKING GROUP LTD HONG KONG",
    label: "ANZH - ANZ BANKING GROUP LTD HONG KONG",
  },
  {
    value: "ANZM - ANZ BANKING GROUP LTD MELBOURNE",
    label: "ANZM - ANZ BANKING GROUP LTD MELBOURNE",
  },
  {
    value: "ARQA - ARQAAM CAPITAL LTD- DXB",
    label: "ARQA - ARQAAM CAPITAL LTD- DXB",
  },
  {
    value: "AXIS - AXIS BANK LTD SINGAPORE BRANCH -SIN",
    label: "AXIS - AXIS BANK LTD SINGAPORE BRANCH -SIN",
  },
  {
    value: "BABC - ARAB BANKING CORPORATION BAHRAIN",
    label: "BABC - ARAB BANKING CORPORATION BAHRAIN",
  },
  {
    value: "BARC - BARCLAYS BANK PLC LONDON",
    label: "BARC - BARCLAYS BANK PLC LONDON",
  },
  {
    value: "BARL - BARCLAYS BANK PLC LONDON 1",
    label: "BARL - BARCLAYS BANK PLC LONDON 1",
  },
  {
    value: "BCIN - BARCLAYS CAPITAL INC 7256",
    label: "BCIN - BARCLAYS CAPITAL INC 7256",
  },
  {
    value: "BCOM - BANK OF COMMUNICATION",
    label: "BCOM - BANK OF COMMUNICATION",
  },
  {
    value: "BCPS - BCP SECURITIES LLC  USA",
    label: "BCPS - BCP SECURITIES LLC  USA",
  },
  {
    value: "BNP CE - BNP PARIBAS HONGKONG",
    label: "BNP CE - BNP PARIBAS HONGKONG",
  },
  {
    value: "BNPL - BNP PARIBAS LONDON FI",
    label: "BNPL - BNP PARIBAS LONDON FI",
  },
  {
    value: "BNPP - BNP PARIBAS C+IB PARIS",
    label: "BNPP - BNP PARIBAS C+IB PARIS",
  },
  {
    value: "BOC - BANK OF CHINA HONG KONG LIMITED",
    label: "BOC - BANK OF CHINA HONG KONG LIMITED",
  },
  {
    value: "BOC 01 - BANK OF CHINA HONG KONG LTD-HK",
    label: "BOC 01 - BANK OF CHINA HONG KONG LTD-HK",
  },
  {
    value: "BOC 13309 - BANK OF CHINA (PB BROKER ONLY)",
    label: "BOC 13309 - BANK OF CHINA (PB BROKER ONLY)",
  },
  {
    value: "BOC25985 - BANK OF CHINA HONG KONG BRANCH",
    label: "BOC25985 - BANK OF CHINA HONG KONG BRANCH",
  },
  {
    value: "BOCB - BANK OF CHINA BEIJING",
    label: "BOCB - BANK OF CHINA BEIJING",
  },
  {
    value: "BOCH - BANK OF CHINA HONG KONG BRANCH",
    label: "BOCH - BANK OF CHINA HONG KONG BRANCH",
  },
  {
    value: "BOCHK - BANK OF CHINA HONG KONG LIMITED",
    label: "BOCHK - BANK OF CHINA HONG KONG LIMITED",
  },
  {
    value: "BOCI - BANK OF CHINA INTL LONDON",
    label: "BOCI - BANK OF CHINA INTL LONDON",
  },
  {
    value: "BOCOM - BANK OF COMMUNICATION",
    label: "BOCOM - BANK OF COMMUNICATION",
  },
  {
    value: "BONY - BANK OF NEW YORK EC 18026",
    label: "BONY - BANK OF NEW YORK EC 18026",
  },
  {
    value: "BOSC - BOSCI - TP BALANCED FUND SP",
    label: "BOSC - BOSCI - TP BALANCED FUND SP",
  },
  {
    value: "BRC 21057 - BARCLAYS DTC 0229",
    label: "BRC 21057 - BARCLAYS DTC 0229",
  },
  {
    value: "BSIL - BOCOM INTERNATIONAL SECS EC47765",
    label: "BSIL - BOCOM INTERNATIONAL SECS EC47765",
  },
  {
    value: "BTIG - BTIG FIXED INCOME",
    label: "BTIG - BTIG FIXED INCOME",
  },
  {
    value: "CCBS - CCB INTERNATIONAL SECU LTD - HKG",
    label: "CCBS - CCB INTERNATIONAL SECU LTD - HKG",
  },
  {
    value: "CEBH - CHINA EVERBRIGHT SEC HK LTD-HKG 1",
    label: "CEBH - CHINA EVERBRIGHT SEC HK LTD-HKG 1",
  },
  {
    value: "CEBI - CEBI FINCL PRDTS LTD 79157",
    label: "CEBI - CEBI FINCL PRDTS LTD 79157",
  },
  {
    value: "CEDEL22205 - WELLS FARGO BANK NA2",
    label: "CEDEL22205 - WELLS FARGO BANK NA2",
  },
  {
    value: "CICC - CHINA INTERNATIONAL CAPITAL CORP HK",
    label: "CICC - CHINA INTERNATIONAL CAPITAL CORP HK",
  },
  {
    value: "CIMB - CIMB BANK BERHAD",
    label: "CIMB - CIMB BANK BERHAD",
  },
  {
    value: "CISB - CHINA INVESTMENT SECS INTERNATIONAL",
    label: "CISB - CHINA INVESTMENT SECS INTERNATIONAL",
  },
  {
    value: "CITI - CITIGROUP GLOBAL MARKETS INC",
    label: "CITI - CITIGROUP GLOBAL MARKETS INC",
  },
  {
    value: "CITI DTC 0908 - CITIGROUP GLBL MRKT LTD",
    label: "CITI DTC 0908 - CITIGROUP GLBL MRKT LTD",
  },
  {
    value: "CITIC28789 - CITIC SC BRKG HK LTD",
    label: "CITIC28789 - CITIC SC BRKG HK LTD",
  },
  {
    value: "CITIC29121 - CITIC SC BRKG HK LTD",
    label: "CITIC29121 - CITIC SC BRKG HK LTD",
  },
  {
    value: "CMBI - CMB INTERNATIONAL SECURITIES LTD",
    label: "CMBI - CMB INTERNATIONAL SECURITIES LTD",
  },
  {
    value: "CMS - CHINA MERCHANTS SECURITS HK CO",
    label: "CMS - CHINA MERCHANTS SECURITS HK CO",
  },
  {
    value: "CMS EC 18665 - CHINA MERCHANTS SECS",
    label: "CMS EC 18665 - CHINA MERCHANTS SECS",
  },
  {
    value: "CMSH - CHINA MERCHANTS SECS CEDEL 18705",
    label: "CMSH - CHINA MERCHANTS SECS CEDEL 18705",
  },
  {
    value: "CNCB - CHINA CITIC BANK INTERNATIONAL LTD",
    label: "CNCB - CHINA CITIC BANK INTERNATIONAL LTD",
  },
  {
    value: "CNCBI21788 - CHINA CITIC BANK INTERNATIONAL LTD",
    label: "CNCBI21788 - CHINA CITIC BANK INTERNATIONAL LTD",
  },
  {
    value: "CONV - NOMURA PRIME SWITCH(NON CREST)",
    label: "CONV - NOMURA PRIME SWITCH(NON CREST)",
  },
  {
    value: "CRAG - CREDIT AGRICOLE CIB PARIS-CDL 70496",
    label: "CRAG - CREDIT AGRICOLE CIB PARIS-CDL 70496",
  },
  {
    value: "CRIC - CHINA CITIC BANK INTERNATIONAL LIMI",
    label: "CRIC - CHINA CITIC BANK INTERNATIONAL LIMI",
  },
  {
    value: "CRIE - CHINA CITIC BANK INTERNATIONAL LTD",
    label: "CRIE - CHINA CITIC BANK INTERNATIONAL LTD",
  },
  {
    value: "CS - CREDIT SUISSE INTERNATIONAL",
    label: "CS - CREDIT SUISSE INTERNATIONAL",
  },
  {
    value: "CSCI - CHINA SECS INTL BROKERAGE CO LTD",
    label: "CSCI - CHINA SECS INTL BROKERAGE CO LTD",
  },
  {
    value: "CSUS - CREDIT SUISSE SECURITIES LLC USA",
    label: "CSUS - CREDIT SUISSE SECURITIES LLC USA",
  },
  {
    value: "CTIC - CITIC SC BRKG HK LTD",
    label: "CTIC - CITIC SC BRKG HK LTD",
  },
  {
    value: "CVSL - BANK OF CHINA (PB BROKER ONLY)",
    label: "CVSL - BANK OF CHINA (PB BROKER ONLY)",
  },
  {
    value: "CWLB - WING LUNG BANK LIMITED HONG KONG",
    label: "CWLB - WING LUNG BANK LIMITED HONG KONG",
  },
  {
    value: "CWSI - WING LUNG BANK LIMITED HONG KONG",
    label: "CWSI - WING LUNG BANK LIMITED HONG KONG",
  },
  {
    value: "DAIW - DAIWA CAPITAL MARKETS HK EC 98891",
    label: "DAIW - DAIWA CAPITAL MARKETS HK EC 98891",
  },
  {
    value: "DAIWA1 - DAIWA CAPTL MKTS HK LTD",
    label: "DAIWA1 - DAIWA CAPTL MKTS HK LTD",
  },
  {
    value: "DB - DEUTSCHE BANK AG LDN EC91255",
    label: "DB - DEUTSCHE BANK AG LDN EC91255",
  },
  {
    value: "DBS - DBS BANK SINGAPORE",
    label: "DBS - DBS BANK SINGAPORE",
  },
  {
    value: "DBSH - DBS BANK HK LIMITED-HONG KONG",
    label: "DBSH - DBS BANK HK LIMITED-HONG KONG",
  },
  {
    value: "EBSI - CHINA EVERBRIGHT SEC HK LTD-HKG 1",
    label: "EBSI - CHINA EVERBRIGHT SEC HK LTD-HKG 1",
  },
  {
    value: "EIB - EMIRATES INVESTMENT BANK",
    label: "EIB - EMIRATES INVESTMENT BANK",
  },
  {
    value: "EMNBD - EMIRATES NBD BANK PJSC",
    label: "EMNBD - EMIRATES NBD BANK PJSC",
  },
  {
    value: "ENBD - EMIRATES NBD PJSC SINGAPORE",
    label: "ENBD - EMIRATES NBD PJSC SINGAPORE",
  },
  {
    value: "ENDD - EMIRATES NBD BANK PJSC",
    label: "ENDD - EMIRATES NBD BANK PJSC",
  },
  {
    value: "GFI - GFI SECURITIES LIMITED SC EC",
    label: "GFI - GFI SECURITIES LIMITED SC EC",
  },
  {
    value: "GS - GOLDMAN SACHS INTL",
    label: "GS - GOLDMAN SACHS INTL",
  },
  {
    value: "GTJA - GUOTAI JUNAN SECURITIES (HK)",
    label: "GTJA - GUOTAI JUNAN SECURITIES (HK)",
  },
  {
    value: "HAIT - HAITONG INTERNATIONAL SECURITIES CO",
    label: "HAIT - HAITONG INTERNATIONAL SECURITIES CO",
  },
  {
    value: "HSBC - HONG KONG AND SHANGHAI - HKG",
    label: "HSBC - HONG KONG AND SHANGHAI - HKG",
  },
  {
    value: "HUAT - HUATAI FINANCIAL HOLDINGS HK LTD",
    label: "HUAT - HUATAI FINANCIAL HOLDINGS HK LTD",
  },
  {
    value: "HUATAI - HUATAI FINANCIAL HOLDINGS HK LTD",
    label: "HUATAI - HUATAI FINANCIAL HOLDINGS HK LTD",
  },
  {
    value: "ICBA - ICBC ASIA LIMITED",
    label: "ICBA - ICBC ASIA LIMITED",
  },
  {
    value: "ICBC - ICBC STANDARD BANK PLC LONDON",
    label: "ICBC - ICBC STANDARD BANK PLC LONDON",
  },
  {
    value: "ICBC20563 - ICBC INT SEC LTD CEDEL 20563",
    label: "ICBC20563 - ICBC INT SEC LTD CEDEL 20563",
  },
  {
    value: "ICBI - ICBC INT SEC LTD CEDEL 20563",
    label: "ICBI - ICBC INT SEC LTD CEDEL 20563",
  },
  {
    value: "ICBI21736 - ICBC INTERNATIONAL SECURITIES LTD",
    label: "ICBI21736 - ICBC INTERNATIONAL SECURITIES LTD",
  },
  { value: "ING - ING BANK NV", label: "ING - ING BANK NV" },
  {
    value: "ING UID-INZ - ING BANK NV",
    label: "ING UID-INZ - ING BANK NV",
  },
  {
    value: "IPL - INSTINET PACIFIC LIMITED HKG",
    label: "IPL - INSTINET PACIFIC LIMITED HKG",
  },
  {
    value: "JEFF - JEFFERIES INTERNATIONAL LTD LDN",
    label: "JEFF - JEFFERIES INTERNATIONAL LTD LDN",
  },
  {
    value: "JPML - JP MORGAN MARKETS LIMITED LDN1",
    label: "JPML - JP MORGAN MARKETS LIMITED LDN1",
  },
  {
    value: "JPMS - JP MORGAN SECURITIES LITD LDN LDSL",
    label: "JPMS - JP MORGAN SECURITIES LITD LDN LDSL",
  },
  { value: "KDB - KDB ASIA LIMITED", label: "KDB - KDB ASIA LIMITED" },
  {
    value: "KGI - KGI ASIA LIMITED - HONG KONG",
    label: "KGI - KGI ASIA LIMITED - HONG KONG",
  },
  {
    value: "KOTA - KOTAK MAHINDRA UK LTD LDN",
    label: "KOTA - KOTAK MAHINDRA UK LTD LDN",
  },
  {
    value: "LIFI - LIQUIDITY FINANCE LLP-LDN",
    label: "LIFI - LIQUIDITY FINANCE LLP-LDN",
  },
  {
    value: "LINE - LINEAR INVESTMENTS LTD 41048",
    label: "LINE - LINEAR INVESTMENTS LTD 41048",
  },
  {
    value: "MACL - MARKETAXESS CAP LTD",
    label: "MACL - MARKETAXESS CAP LTD",
  },
  {
    value: "MADW - MIRAE ASSET DAEWOO CO LTD",
    label: "MADW - MIRAE ASSET DAEWOO CO LTD",
  },
  {
    value: "MAND - MANDIRI SECURITIES PTE LTD -SIN",
    label: "MAND - MANDIRI SECURITIES PTE LTD -SIN",
  },
  {
    value: "MIZU - MIZUHO SECURITIES ASIA LTD HKG",
    label: "MIZU - MIZUHO SECURITIES ASIA LTD HKG",
  },
  {
    value: "MIZUX - MIZUHO SECURITIES ASIA LTD HKG",
    label: "MIZUX - MIZUHO SECURITIES ASIA LTD HKG",
  },
  {
    value: "ML 15366 - MERRILL LYNCH INT EC 15366",
    label: "ML 15366 - MERRILL LYNCH INT EC 15366",
  },
  {
    value: "MLI - MERRILL LYNCH INTL LDN EC 92707",
    label: "MLI - MERRILL LYNCH INTL LDN EC 92707",
  },
  {
    value: "MLPF - MERRILL LYNCH PIERCE FENNER",
    label: "MLPF - MERRILL LYNCH PIERCE FENNER",
  },
  {
    value: "MS - MORGAN STANLEY PLC LDN EC98366",
    label: "MS - MORGAN STANLEY PLC LDN EC98366",
  },
  {
    value: "MTBS - MITSUBISHI UFJ SEC INT PLC LDN",
    label: "MTBS - MITSUBISHI UFJ SEC INT PLC LDN",
  },
  {
    value: "MUTI - MITSUBISHI UFJ TRUST INTL LTD LDN",
    label: "MUTI - MITSUBISHI UFJ TRUST INTL LTD LDN",
  },
  {
    value: "NAB - NATIONAL AUSTRALIA BANK LTD LONDON",
    label: "NAB - NATIONAL AUSTRALIA BANK LTD LONDON",
  },
  {
    value: "NABB - NATIONAL AUSTRALIA BK HONG KONG BR",
    label: "NABB - NATIONAL AUSTRALIA BK HONG KONG BR",
  },
  {
    value: "NABME - NAB MELBOURNE FI EC40242",
    label: "NABME - NAB MELBOURNE FI EC40242",
  },
  { value: "NATI - NATIXIS PARIS", label: "NATI - NATIXIS PARIS" },
  {
    value: "NATW - NATWEST MARKETS PLC",
    label: "NATW - NATWEST MARKETS PLC",
  },
  {
    value: "NBAD - NAT BNK OF ABUDHABI UAE",
    label: "NBAD - NAT BNK OF ABUDHABI UAE",
  },
  {
    value: "NIKO - SMBC NIKKO CAPITAL MARKETS LTD",
    label: "NIKO - SMBC NIKKO CAPITAL MARKETS LTD",
  },
  {
    value: "NMR - NIP DONE WITH BROKER",
    label: "NMR - NIP DONE WITH BROKER",
  },
  {
    value: "NORB - NOOR ISLAMIC BANK-DUB",
    label: "NORB - NOOR ISLAMIC BANK-DUB",
  },
  {
    value: "OCBC - OVERSEA CHINESE BKING CORP LTD-SING",
    label: "OCBC - OVERSEA CHINESE BKING CORP LTD-SING",
  },
  {
    value: "OCTO - OCTO FINANCES SA",
    label: "OCTO - OCTO FINANCES SA",
  },
  {
    value: "OISS - ORBIT INVT SEC SERV LTD",
    label: "OISS - ORBIT INVT SEC SERV LTD",
  },
  {
    value: "OPPH - OPPENHEIMER AND CO INC NYK",
    label: "OPPH - OPPENHEIMER AND CO INC NYK",
  },
  {
    value: "PARE - BANK OF COMMUNICATIONS CO LTD",
    label: "PARE - BANK OF COMMUNICATIONS CO LTD",
  },
  {
    value: "PATO - PARETO SECURITIES AS - OSLO",
    label: "PATO - PARETO SECURITIES AS - OSLO",
  },
  {
    value: "RBCC - RBC CAPITAL MARKETS LLC",
    label: "RBCC - RBC CAPITAL MARKETS LLC",
  },
  {
    value: "RENA - RENAISSANCE SEC CYPRUS LIMITED",
    label: "RENA - RENAISSANCE SEC CYPRUS LIMITED",
  },
  {
    value: "SC 73378 - STANDARD CHARTERED BANK HK CS 73378",
    label: "SC 73378 - STANDARD CHARTERED BANK HK CS 73378",
  },
  {
    value: "SC LOWY - SC LOWY FINANCIAL HK LTD - NIP PB",
    label: "SC LOWY - SC LOWY FINANCIAL HK LTD - NIP PB",
  },
  {
    value: "SCB - STANDARD CHARTERED BANK HONG KONG",
    label: "SCB - STANDARD CHARTERED BANK HONG KONG",
  },
  {
    value: "SCBL - STANDARD CHARTERED BANK LONDON",
    label: "SCBL - STANDARD CHARTERED BANK LONDON",
  },
  {
    value: "SCBS - STANDARD CHARTERED BANK SINGAPORE",
    label: "SCBS - STANDARD CHARTERED BANK SINGAPORE",
  },
  {
    value: "SCLO - SC LOWY FINANCIAL HK LTD - NIP PB",
    label: "SCLO - SC LOWY FINANCIAL HK LTD - NIP PB",
  },
  {
    value: "SEBA - STIFEL EUROPE BANK",
    label: "SEBA - STIFEL EUROPE BANK",
  },
  {
    value: "SEEC - STIFEL EUROPE BANK",
    label: "SEEC - STIFEL EUROPE BANK",
  },
  {
    value: "SG - SOCIETE GENERALE PARIS FI",
    label: "SG - SOCIETE GENERALE PARIS FI",
  },
  {
    value: "SGX - SGX BOND TRADING PTE LTD",
    label: "SGX - SGX BOND TRADING PTE LTD",
  },
  {
    value: "SHUA - SHUAA CAPITAL PSC",
    label: "SHUA - SHUAA CAPITAL PSC",
  },
  {
    value: "SPDB - SHANGHAI PUDONG DEV BK CO LTD HK BR",
    label: "SPDB - SHANGHAI PUDONG DEV BK CO LTD HK BR",
  },
  {
    value: "SPDB17055 - SHANGHAI PUDONG DEV BK CO LTD HK BR",
    label: "SPDB17055 - SHANGHAI PUDONG DEV BK CO LTD HK BR",
  },
  {
    value: "SPDB22136 - SHANGHAI PUDONG DEVELOPMENT BK",
    label: "SPDB22136 - SHANGHAI PUDONG DEVELOPMENT BK",
  },
  {
    value: "SPDI - SPDB INTL INV MGMT LTD -HKG",
    label: "SPDI - SPDB INTL INV MGMT LTD -HKG",
  },
  {
    value: "SPEL - THE SEAPORT GROUP EUROPE LLP LON",
    label: "SPEL - THE SEAPORT GROUP EUROPE LLP LON",
  },
  {
    value: "SSIB - SOOCHOW SECURITIES INTL BRK LD -CEN",
    label: "SSIB - SOOCHOW SECURITIES INTL BRK LD -CEN",
  },
  {
    value: "SUNG - SUN GLOBAL INVESTMENT LIMITED",
    label: "SUNG - SUN GLOBAL INVESTMENT LIMITED",
  },
  {
    value: "SWS - SHENWAN HONGYUAN SECS H K LTD",
    label: "SWS - SHENWAN HONGYUAN SECS H K LTD",
  },
  {
    value: "TFI - BANK OF CHINA HONG KONG LIMITED",
    label: "TFI - BANK OF CHINA HONG KONG LIMITED",
  },
  {
    value: "TFIG - CHN MINSHENG BANK CORP LTD CE 17687",
    label: "TFIG - CHN MINSHENG BANK CORP LTD CE 17687",
  },
  {
    value: "TFIS - BANK OF CHINA HONG KONG LIMITED",
    label: "TFIS - BANK OF CHINA HONG KONG LIMITED",
  },
  {
    value: "THSL - CHINA TONGHAI SECURITIES LIMITED",
    label: "THSL - CHINA TONGHAI SECURITIES LIMITED",
  },
  {
    value: "UBS LTD 18990 - UBS LIMITED CEDEL 18990",
    label: "UBS LTD 18990 - UBS LIMITED CEDEL 18990",
  },
  {
    value: "UBSA - UBS AG LDN CEDEL 18800",
    label: "UBSA - UBS AG LDN CEDEL 18800",
  },
  { value: "UBSL - UBS LIMITED", label: "UBSL - UBS LIMITED" },
  {
    value: "UBSS - UBS SECURITIES ASIA LIMITED",
    label: "UBSS - UBS SECURITIES ASIA LIMITED",
  },
  {
    value: "UOBL - UNITED OVERSEAS BK LTD SIN FID",
    label: "UOBL - UNITED OVERSEAS BK LTD SIN FID",
  },
  {
    value: "VCL - BANK OF CHINA HONG KONG LIMITED",
    label: "VCL - BANK OF CHINA HONG KONG LIMITED",
  },
  {
    value: "VCM - VANTAGE CAPITAL MARKETS LLP LONDON",
    label: "VCM - VANTAGE CAPITAL MARKETS LLP LONDON",
  },
  {
    value: "WELF - WELLS FARGO SECURITIES LLC USA",
    label: "WELF - WELLS FARGO SECURITIES LLC USA",
  },
  {
    value: "XYZQ - INDUSTRIAL SECURITIES HONG KONG",
    label: "XYZQ - INDUSTRIAL SECURITIES HONG KONG",
  },
  {
    value: "YXSS - STANDARD CHARTERED BANK HONG KONG",
    label: "YXSS - STANDARD CHARTERED BANK HONG KONG",
  },
  {
    value: "ZTFP - ZHONGTAI INT FINANCIAL PRODUCTS",
    label: "ZTFP - ZHONGTAI INT FINANCIAL PRODUCTS",
  },
];

export let dataBrokerFullNames = [
  {
    label: "J.P. Morgan Securities plc",
    value: "J.P. Morgan Securities plc",
  },
  {
    label: "HONGKONG AND SHANGHAI BANKING CORPORATION LTD",
    value: "HONGKONG AND SHANGHAI BANKING CORPORATION LTD",
  },
  {
    label: "Guotai Juran Securities (Hong Kong) Limited",
    value: "Guotai Juran Securities (Hong Kong) Limited",
  },
  {
    label: "Vantage Capital Markets LLP",
    value: "Vantage Capital Markets LLP",
  },
  { label: "Wells Fargo Securities", value: "Wells Fargo Securities" },
  {
    label: "Standard Chartered Bank",
    value: "Standard Chartered Bank",
  },
  { label: "BNP Paribas", value: "BNP Paribas" },
  { label: "DBS", value: "DBS" },
  {
    label: "Deutsche Bank AG, London",
    value: "Deutsche Bank AG, London",
  },
  {
    label: "Goldman Sachs International (GSI)",
    value: "Goldman Sachs International (GSI)",
  },
  { label: "UBS AG London Branch", value: "UBS AG London Branch" },
  {
    label: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK",
    value: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK",
  },
  {
    label: "SMBC Nikko Capital Markets Ltd",
    value: "SMBC Nikko Capital Markets Ltd",
  },
  {
    label: "Jefferies International Limited",
    value: "Jefferies International Limited",
  },
];

export let settlementVenueBroker = [
  {
    label: "J.P. Morgan Securities plc Euroclear A/C 16728",
    value: "J.P. Morgan Securities plc Euroclear A/C 16728",
  },
  {
    label: "J.P. Morgan Securities plc FedWire JPM  Depot: CHASE FED BDAS && Account No: ABA 021000018 BK OF NYC/LDSL",
    value: "J.P. Morgan Securities plc FedWire JPM  Depot: CHASE FED BDAS && Account No: ABA 021000018 BK OF NYC/LDSL",
  },
  {
    label: "HONGKONG AND SHANGHAI BANKING CORPORATION LTD Euroclear A/C 92598",
    value: "HONGKONG AND SHANGHAI BANKING CORPORATION LTD Euroclear A/C 92598",
  },
  {
    label: "Guotai Juran Securities (Hong Kong) Limited Euroclear A/C 42035",
    value: "Guotai Juran Securities (Hong Kong) Limited Euroclear A/C 42035",
  },
  {
    label: "Vantage Capital Markets LLP Euroclear A/C 25213",
    value: "Vantage Capital Markets LLP Euroclear A/C 25213",
  },
  {
    label: "Wells Fargo Securities Cedel A/C 22198",
    value: "Wells Fargo Securities Cedel A/C 22198",
  },
  {
    label: "Standard Chartered Bank Clearstream A/C 73390",
    value: "Standard Chartered Bank Clearstream A/C 73390",
  },
  {
    label: "BNP Paribas Euroclear A/C 99290",
    value: "BNP Paribas Euroclear A/C 99290",
  },
  {
    label: "Deutsche Bank AG, London Euroclear A/C 91255",
    value: "Deutsche Bank AG, London Euroclear A/C 91255",
  },
  {
    label: "Goldman Sachs International (GSI) Euroclear A/C 94589",
    value: "Goldman Sachs International (GSI) Euroclear A/C 94589",
  },
  {
    label: "UBS AG London Branch Clearstream A/C 18800",
    value: "UBS AG London Branch Clearstream A/C 18800",
  },
  {
    label: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK Euroclear A/C 90555",
    value: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK Euroclear A/C 90555",
  },
  {
    label: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK FedWire ACCOUNT: 10073435 && BENEFICIARY: Crédit Agricole CIB - BSUIFRPPXXX && SEC_SETTLEMENT_SYST: FEDWIRE && ABA: 021000018 BKOFNYC/CABANK",
    value: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK FedWire ACCOUNT: 10073435 && BENEFICIARY: Crédit Agricole CIB - BSUIFRPPXXX && SEC_SETTLEMENT_SYST: FEDWIRE && ABA: 021000018 BKOFNYC/CABANK",
  },
  {
    label: "SMBC Nikko Capital Markets Ltd Euroclear A/C 15937",
    value: "SMBC Nikko Capital Markets Ltd Euroclear A/C 15937",
  },
  {
    label: "SMBC Nikko Capital Markets Ltd FedWire Clearer : Federal Reserve Bank of NY (FED) && Custodian : BONY MELLON IRVTUS3NBDS (BIC: IRVTUS3NBDS) && Custodian Acc : SKU && Beneficiary : SMBC Nikko Capital Markets Limited && Beneficiary Acc : SKU",
    value: "SMBC Nikko Capital Markets Ltd FedWire Clearer : Federal Reserve Bank of NY (FED) && Custodian : BONY MELLON IRVTUS3NBDS (BIC: IRVTUS3NBDS) && Custodian Acc : SKU && Beneficiary : SMBC Nikko Capital Markets Limited && Beneficiary Acc : SKU",
  },
  {
    label: "Jefferies International Limited Euroclear A/C 97618",
    value: "Jefferies International Limited Euroclear A/C 97618",
  },
];

export let brokerEmails = [
  {
    label: "J.P. Morgan Securities plc  && asian.FI.Sales.Support@jpmorgan.com",
    value: "asian.FI.Sales.Support@jpmorgan.com",
  },
  {
    label: "HONGKONG AND SHANGHAI BANKING CORPORATION LTD  && tryficonfo@hsbc.com.hk",
    value: "tryficonfo@hsbc.com.hk",
  },
  {
    label: "Guotai Juran Securities (Hong Kong) Limited  && otc.ops@gtjas.com.hk",
    value: "otc.ops@gtjas.com.hk",
  },
  {
    label: "Vantage Capital Markets LLP  && mkewlani@vcmllp.com",
    value: "mkewlani@vcmllp.com",
  },
  {
    label: "Standard Chartered Bank  && fi.setts@sc.com",
    value: "fi.setts@sc.com",
  },
  {
    label: "BNP Paribas  && apac.gm.bond.trade.support@asia.bnpparibas.com",
    value: "apac.gm.bond.trade.support@asia.bnpparibas.com",
  },
  {
    label: "Goldman Sachs International (GSI)  && gs-asia-cmo-fi@ny.email.gs.com",
    value: "gs-asia-cmo-fi@ny.email.gs.com",
  },
  {
    label: "UBS AG London Branch  && sh-ficc-client-team@ubs.com",
    value: "sh-ficc-client-team@ubs.com",
  },
  {
    label: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK  && bondsettlement@ca-cib.com",
    value: "bondsettlement@ca-cib.com",
  },
  {
    label: "SMBC Nikko Capital Markets Ltd  && SBNIKKOoperations@smbcnikko-cm.com",
    value: "SBNIKKOoperations@smbcnikko-cm.com",
  },
  {
    label: "Jefferies International Limited  && INTLFISETTLEMENTS@Jefferies.com",
    value: "INTLFISETTLEMENTS@Jefferies.com",
  },
];

// const message = {
//   "J.P. Morgan Securities plc": { "Euroclear": "A/C 16728", "Clearstream": "", "Cedel": "", "FedWire": "JPM  Depot: CHASE FED BDAS && Account No: ABA 021000018 BK OF NYC/LDSL", email: "asian.FI.Sales.Support@jpmorgan.com" },
//   "HONGKONG AND SHANGHAI BANKING CORPORATION LTD": { "Euroclear": "A/C 92598", "Clearstream": "", "Cedel": "", "FedWire": "", email: "tryficonfo@hsbc.com.hk" },
//   "Guotai Juran Securities (Hong Kong) Limited": { "Euroclear": "A/C 42035", "Clearstream": "", "Cedel": "", "FedWire": "", email: "otc.ops@gtjas.com.hk" },
//   "Vantage Capital Markets LLP": { "Euroclear": "A/C 25213", "Clearstream": "", "Cedel": "", "FedWire": "", email: "mkewlani@vcmllp.com" },
//   "Wells Fargo Securities": { "Euroclear": "", "Clearstream": "", "Cedel": "A/C 22198", "FedWire": "", email: "" },
//   "Standard Chartered Bank": { "Euroclear": "", "Clearstream": "A/C 73390", "Cedel": "", "FedWire": "", email: "fi.setts@sc.com" },
//   "BNP Paribas": { "Euroclear": "A/C 99290", "Clearstream": "", "Cedel": "", "FedWire": "", email: "apac.gm.bond.trade.support@asia.bnpparibas.com" },
//   "Deutsche Bank AG, London": { "Euroclear": "A/C 91255", "Clearstream": "", "Cedel": "", "FedWire": "", email: "" },
//   "Goldman Sachs International (GSI)": { "Euroclear": "A/C 94589", "Clearstream": "", "Cedel": "", "FedWire": "", email: "gs-asia-cmo-fi@ny.email.gs.com" },
//   "UBS AG London Branch": { "Euroclear": "", "Clearstream": "A/C 18800", "Cedel": "", "FedWire": "", email: "sh-ficc-client-team@ubs.com" },
//   "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK": { "Euroclear": "A/C 90555", "Clearstream": "", "Cedel": "", "FedWire": "ACCOUNT: 10073435 && BENEFICIARY: Crédit Agricole CIB - BSUIFRPPXXX && SEC_SETTLEMENT_SYST: FEDWIRE && ABA: 021000018 BKOFNYC/CABANK", email: "bondsettlement@ca-cib.com" },
//   "SMBC Nikko Capital Markets Ltd": { "Euroclear": "A/C 15937", "Clearstream": "", "Cedel": "", "FedWire": "Clearer : Federal Reserve Bank of NY (FED) && Custodian : BONY MELLON IRVTUS3NBDS (BIC: IRVTUS3NBDS) && Custodian Acc : SKU && Beneficiary : SMBC Nikko Capital Markets Limited && Beneficiary Acc : SKU", email: "SBNIKKOoperations@smbcnikko-cm.com" },
//   "Jefferies International Limited": { "Euroclear": "A/C 97618", "Clearstream": "", "Cedel": "", "FedWire": "", email: "INTLFISETTLEMENTS@Jefferies.com" },
// }

// let settlementBrokerValues = []
// let emails = []
// for (let broker in message) {
//   for (let venue in message[broker]) {
//     if (message[broker][venue] != "" && venue != "email") {

//       let object = { label: `${broker} ${venue} ${message[broker][venue]}`, value: venue }
//       settlementBrokerValues.push(object)
//     }

//   }
//   if(message[broker]["email"]!=""){
//       let object = { label: message[broker]["email"], value: message[broker]["email"] }
//       emails.push(object)
//   }

// }
// // console.log(settlementBrokerValues)

// console.log(emails)
