import { createBrowserRouter } from "react-router-dom";

import ViewEBlot from "../components/eblot/viewEblot";
import ErrorPage from "../common/errorPage";
import NewIssues from "../components/eblot/newIssues";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ViewEBlot />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-new-issues",
    element: <NewIssues />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
