import { useState } from "react";
import { CloseIcon, getCurrentDateYYYYMMDD } from "../../../common/functions";
import CreatableSelect from "react-select/creatable";

function AddTradeEblot(props: any) {
  let editTableTitles = props.editTableTitles;
  let newIssuesNames: any = [];
  for (let index = 0; index < props.newIssues.length; index++) {
    let trade = props.newIssues[index];
    let show = trade["BB Ticker"] + ", scraped on " + trade["Trade Date"];
    trade["Price"] = trade["Issue Price"];
    trade["Trade Date"] = getCurrentDateYYYYMMDD(trade["Trade Date"]);
    trade["Settle Date"] = getCurrentDateYYYYMMDD(trade["Settle Date"]);

    newIssuesNames.push({ name: trade["BB Ticker"], label: show, value: trade });
  }
  let tsy: any = [],
    corp: any = [];
  for (let position of props.tickers.tsy) {
    tsy.push({ name: position["BB Ticker"], label: position["BB Ticker"], value: { "BB Ticker": position["BB Ticker"], ISIN: position["ISIN"], Currency: position["Currency"] } });
  }
  for (let position of props.tickers.corp) {
    corp.push({ name: position["BB Ticker"], label: position["BB Ticker"], value: { "BB Ticker": position["BB Ticker"], ISIN: position["ISIN"], Currency: position["Currency"] } });
  }
  let currencies = ["USD", "EUR", "AUD", "GBP", "YEN", "CNH"];
  let currenciesFormat = [];
  for (let index = 0; index < currencies.length; index++) {
    let currency = currencies[index];

    currenciesFormat.push({ label: currency, value: currency });
  }
  return (
    <div className="position-container edit-info-container-2 edit-info-container-4">
      <div onClick={(event) => props.cancelTradeInfo(event)} className="close-icon-container-edit-position">
        <CloseIcon />
      </div>

      <form
        style={{
          overflowX: "auto",
        }}
      >
        <div>
          <TradeForm editTableTitles={editTableTitles} newTrade={props.newTrade1} setNewTrade={props.setNewTrade1} tickers={props.tickers} newIssuesNames={[...newIssuesNames, ...corp]} tradeLabel="O-R" currenciesFormat={currenciesFormat} />
          <hr />

          <TradeForm editTableTitles={editTableTitles} newTrade={props.newTrade2} setNewTrade={props.setNewTrade2} tickers={props.tickers} newIssuesNames={[...tsy]} tradeLabel="TSY" treasury={true} currenciesFormat={currenciesFormat} />

          <hr />
        </div>
        <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12" style={{ padding: "0" }}>
          <label className="checkbox-container">
            <input type="checkbox" name="o-r" checked={props.pair} onChange={(event) => props.setPair(event.target.checked)} />
            <span className="checkmark"></span> Pair (The two trades will be linked)
          </label>
        </div>
        <div className="edit-info-edit-container">
          <button
            type="button"
            className="btn upload-btn position-info-edit"
            onClick={(event) => {
              event.preventDefault();
              props.handleAddTrade(event);
            }}
          >
            Add New Trades
          </button>
        </div>
      </form>
      {props.authStatus && <h4 className="error error-position-info">{props.authStatus}</h4>}
    </div>
  );
}

function TradeForm({ editTableTitles, newTrade, setNewTrade, newIssuesNames, tradeLabel, treasury = false, currenciesFormat }: any) {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const isRequired = newTrade["BB Ticker"] ? true : false;
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: "30px", // Set minimum height for the control
      height: "30px", // Set exact height for the control
      width: "90%", // Set exact height for the control
      margin: "auto",

      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        minheight: "30px !important",
        height: "30px !important",
      },
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px", // Set height for the value container
      padding: "0 8px", // Adjust padding as necessary
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        height: "30px !important",
        padding: "0 4px",
      },
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0", // Adjust margin for the input
      padding: "0", // Ensure no padding for the input
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        margin: "0",
        padding: "0",
      },
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      // height: '30px', // Set height for the indicator separator
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        // height: "30px !important",
      },
    }),
    ClearIndicator: (provided: any, state: any) => ({
      ...provided,
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        height: "30px !important",
        width: "10px !important",
      },
    }),

    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px", // Set height for the indicators container
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        height: "30px !important",
        padding: "0px !important",
      },
    }),
    indicatorContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px", // Set height for the indicators container
      "@media (max-width: 1000px)": {
        // Adjust for mobile devices
        height: "30px !important",
        padding: "0px !important",
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999, // Ensure the menu is above other elements
    }),
  };
  console.log({ newTrade });
  return (
    <div>
      <p>{tradeLabel}</p>
      <table id="table-id" className="table table-hover table-portfolio table-edit">
        <tbody>
          <tr id="first-row">
            <td>{editTableTitles[0]}</td>
            <td>
              <select
                id={editTableTitles[0]}
                name={editTableTitles[0]}
                className="ninety-width"
                onChange={(event) => {
                  const updatedTrade = { ...newTrade, [editTableTitles[0]]: event.target.value };
                  setNewTrade(updatedTrade);
                }}
                required={isRequired}
              >
                {treasury ? (
                  <>
                    <option value={"S"}>S</option>
                    <option value={"B"}>B</option>
                  </>
                ) : (
                  <>
                    <option value={"B"}>B</option>
                    <option value={"S"}>S</option>
                  </>
                )}
              </select>
            </td>
            <td>{editTableTitles[1]}</td>
            <td>
              <CreatableSelect
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                value={newTrade[editTableTitles[1]] ? { value: newTrade[editTableTitles[1]], label: newTrade[editTableTitles[1]] } : { value: "", label: "" }}
                name={editTableTitles[1]}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                options={newIssuesNames}
                styles={customStyles}
                onChange={(event: any) => {
                  if (event) {
                    const selectedValue = event.name || event.label;
                    const selectedTrade = newIssuesNames.find((trade: any) => trade.name == selectedValue);

                    let temp: any = { ...newTrade };
                    if (selectedTrade) {
                      for (let key in selectedTrade.value) {
                        if (key != "id") {
                          temp[key] = selectedTrade.value[key];
                        }
                      }
                      if (!treasury) {
                        let weekendExtra = new Date().getDay() == 5 ? 2 * 24 * 60 * 60 * 1000 : 0;
                        if (selectedTrade.value["Currency"] == "USD") {
                          let settleDate = getCurrentDateYYYYMMDD(new Date().getTime() + 1 * 24 * 60 * 60 * 1000 + weekendExtra);
                          temp["Settle Date"] = settleDate;
                        } else if (selectedTrade.value["Currency"] != "USD" && selectedTrade.value["Currency"]) {
                          let settleDate = getCurrentDateYYYYMMDD(new Date().getTime() + 2 * 24 * 60 * 60 * 1000 + weekendExtra);
                          temp["Settle Date"] = settleDate;
                        }
                      }
                      setNewTrade(temp);
                    } else {
                      const updatedTrade = { ...newTrade, [editTableTitles[1]]: selectedValue };
                      setNewTrade(updatedTrade);
                    }
                  } else {
                    const updatedTrade = { ...newTrade, [editTableTitles[1]]: "" };
                    setNewTrade(updatedTrade);
                  }
                }}
              />
            </td>
            <td>{editTableTitles[2]}</td>
            <td>
              <input
                type="date"
                name={editTableTitles[2]}
                onChange={(event) => {
                  const updatedTrade = { ...newTrade, [editTableTitles[2]]: event.target.value };
                  setNewTrade(updatedTrade);
                }}
                value={newTrade[editTableTitles[2]]}
                required={isRequired}
              />
            </td>
          </tr>
          <tr id="second-row">
            <td>{editTableTitles[5]}</td>
            <td>
              <input
                type="text"
                name={editTableTitles[5]}
                onChange={(event) => {
                  let valuePut: any = event.target.value.toString().toLowerCase();
                  if (valuePut.includes("m")) {
                    valuePut = parseFloat(valuePut.replace(/m/g, ""));
                    valuePut = valuePut * 1000000;
                  } else if (valuePut.includes("k")) {
                    valuePut = parseFloat(valuePut.replace(/k/g, ""));
                    valuePut = valuePut * 1000;
                  }
                  const updatedTrade = { ...newTrade, [editTableTitles[5]]: valuePut.toLocaleString() };
                  setNewTrade(updatedTrade);
                }}
                value={newTrade[editTableTitles[5]]}
                required={isRequired}
              />
            </td>
            <td>{editTableTitles[3]}</td>
            {treasury == false ? (
              <td>
                <input
                  type="date"
                  name={editTableTitles[3]}
                  onChange={(event) => {
                    const updatedTrade = { ...newTrade, [editTableTitles[3]]: event.target.value };
                    setNewTrade(updatedTrade);
                  }}
                  value={newTrade[editTableTitles[3]]}
                />
              </td>
            ) : (
              <td>
                <select
                  id={editTableTitles[3]}
                  name={editTableTitles[3]}
                  className="ninety-width"
                  onChange={(event) => {
                    const updatedTrade = { ...newTrade, [editTableTitles[3]]: event.target.value };
                    setNewTrade(updatedTrade);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Settle Date TSY
                  </option>
                  <option value={newTrade[editTableTitles[2]]}>T + 0</option>
                  <option value={getCurrentDateYYYYMMDD(new Date(newTrade[editTableTitles[2]]).getTime() + 1 * 24 * 60 * 60 * 1000)}>T + 1</option>
                  <option value={getCurrentDateYYYYMMDD(new Date(newTrade[editTableTitles[2]]).getTime() + 2 * 24 * 60 * 60 * 1000)}>T + 2</option>
                </select>
              </td>
            )}
            <td>{editTableTitles[4]}</td>
            <td>
              <input
                list={editTableTitles[4]}
                id={editTableTitles[4]}
                name={editTableTitles[4]}
                onChange={(event) => {
                  const updatedTrade = { ...newTrade, [editTableTitles[4]]: event.target.value };
                  setNewTrade(updatedTrade);
                }}
                type="text"
                value={newTrade[editTableTitles[4]]}
                required={isRequired}
              />
            </td>
          </tr>
          <tr id="third-row">
            <td>{editTableTitles[6]}</td>
            <td>
              <input
                type="text"
                name={editTableTitles[6]}
                onChange={(event) => {
                  const updatedTrade = { ...newTrade, [editTableTitles[6]]: event.target.value };
                  setNewTrade(updatedTrade);
                }}
                value={newTrade[editTableTitles[6]]}
              />
            </td>
            <td>{editTableTitles[7]}</td>
            <td>
              <CreatableSelect
                className="basic-single"
                classNamePrefix="select"
                value={newTrade[editTableTitles[7]] ? { value: newTrade[editTableTitles[7]], label: newTrade[editTableTitles[7]] } : { value: "USD", label: "USD" }}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                options={currenciesFormat}
                styles={customStyles}
                required={isRequired}
                onChange={(event: { label: string; value: string } | any) => {
                  if (event) {
                    const updatedTrade = { ...newTrade, [editTableTitles[7]]: event.value };
                    setNewTrade(updatedTrade);
                  } else {
                    const updatedTrade = { ...newTrade, [editTableTitles[7]]: "" };
                    setNewTrade(updatedTrade);
                  }
                }}
              />
              <input type="hidden" value={newTrade[editTableTitles[7]] || ""} required={isRequired} />
            </td>
            <td>{editTableTitles[8]}</td>
            <td>
              <input
                type="text"
                name={editTableTitles[8]}
                onChange={(event) => {
                  const updatedTrade = { ...newTrade, [editTableTitles[8]]: event.target.value };
                  setNewTrade(updatedTrade);
                }}
                value={newTrade[editTableTitles[8]]}
              />
            </td>
          </tr>
          <tr id="four-row">
            <td>{editTableTitles[9]}</td>
            <td>
              <input
                type="text"
                name={editTableTitles[9]}
                onChange={(event) => {
                  const updatedTrade = { ...newTrade, [editTableTitles[9]]: event.target.value };
                  setNewTrade(updatedTrade);
                }}
                value={newTrade[editTableTitles[9]]}
              />
            </td>
          </tr>
          <tr>
            {!treasury && newTrade["Treasury & Spread"] ? <td> Treasury Reference </td> : ""}

            {!treasury && newTrade["Treasury & Spread"] ? <td> {newTrade["Treasury & Spread"]} </td> : ""}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AddTradeEblot;
