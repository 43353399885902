import { useState, useEffect, useCallback, useRef } from "react";
import NavBar from "../../common/navbar";
import { eblotUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import axios from "axios";
import { CloseIcon, getDateTimeInMongoDBCollectionFormat } from "../../common/functions";
import * as XLSX from "xlsx";
import { Add } from "../../common/functions";

let tableTitles: any = ["BB Ticker", "Issue Price", "Trade Date", "Settle Date", "ISIN", "Currency", "Treasury & Spread"];
let tableTitlesSQL: any = ["bb_ticker", "issue_price", "trade_date", "settle_date", "isin", "currency", "treasury_and_spread"];

function NewIssues() {
  let [newIssues, setNewIssues] = useState<any>([]);
  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);
  let [authStatus, setAuthStatus] = useState("");
  const [request, setRequestStatus] = useState(false);
  let [infoDisplay, setInfoDisplay] = useState("none");
  let [infoEdit, setInfoEdit] = useState<any>({});

  const handleContextMenu = useCallback((event: any, newIssues: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setInfoEdit(newIssues);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);
  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);
  let today: any = getDateTimeInMongoDBCollectionFormat(new Date());
  let url: any = eblotUrl + `new-issues`;

  useEffect(() => {
    setRequestStatus(true);
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setNewIssues(data);
        setRequestStatus(false);
      });
  }, []);

  function downloadCSV() {
    let table = document.getElementById("table-id");

    // Use XLSX.utils.table_to_book to convert the table to a workbook
    let wb = XLSX.utils.table_to_book(table, { sheet: "Sheet 1" });

    // Write the workbook to a file
    XLSX.writeFile(wb, `new_issues_${today}.xlsx`);
  }

  async function handleEditAdd(event: any, url: string, props: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData = new FormData(event.target);
    try {
      let auth = await axios.post(eblotUrl + url, formData, postRequestOptions);

      if (auth.data.error) {
        setAuthStatus(auth.data.error);
      } else {
        setTimeout(() => 2000);
        window.location.reload();
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }

  function ShowInfo(props: any) {
    return (
      <div className="position-container edit-info-container-2">
        <div onClick={(event) => props.setDisplay("none")} className="close-icon-container-edit-position">
          <CloseIcon />
        </div>
        <form
          onSubmit={(event) => handleEditAdd(event, props.url, props)}
          style={{
            overflowX: "auto",
          }}
        >
          <table id="table-id" className="table table-hover table-portfolio table-striped table-fund-details">
            <tbody>
              <tr className="sticky-top">
                {tableTitles.map((title: string, index: number) => (
                  <td key={index}>{title}</td>
                ))}
              </tr>
              <tr className="">
                {tableTitlesSQL.map((title: string, index: number) => (
                  <td key={index}>{props.newIssues && title == "isin" ? <input name={title} placeholder={props.newIssues[title]} value={props.newIssues[title]} className="paramDisabled"></input> : title != "id" ? <input name={title} placeholder={props.newIssues ? props.newIssues[title] : ""}></input> : ""}</td>
                ))}
              </tr>
            </tbody>
          </table>
          <div className="edit-info-edit-container">
            <button type="submit" className="btn upload-btn position-info-edit">
              {" "}
              {props.newIssues ? "Edit" : "Add"}
            </button>
          </div>
        </form>
        {authStatus && <h4 className="error error-position-info">{authStatus}</h4>}
      </div>
    );
  }

  async function handleDelete(event: any) {
    setRequestStatus(true);

    let action = window.confirm("Confirm delete trade: " + infoEdit["bb_ticker"] + " on trade date: " + infoEdit["trade_date"]);
    try {
      if (action) {
        let auth = await axios.post(eblotUrl + "delete-new-issue", infoEdit, postRequestOptions);
        console.log({ auth });
        if (auth.data.error) {
          setAuthStatus(auth.data.error);
        } else {
          window.location.href = "/view-new-issues";
        }
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }

  if (newIssues == null || request == true) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <NavBar />

      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
        className="table-container-custom"
      >
        <table id="table-id" onClick={handleClick} className="table table-portfolio  table-striped table-fund-details">
          <tbody>
            <tr className="sticky-top">
              {tableTitles.map((title: string, index: number) => (
                <td key={index}>{title}</td>
              ))}
            </tr>
            {newIssues.map((details: any, index: number) => (
              <tr onContextMenu={(event) => handleContextMenu(event, details)} key={index}>
                {tableTitles.map((title: string, index: number) => (
                  <td key={index}>{details[tableTitlesSQL[index]]}</td>
                ))}
              </tr>
            ))}
            {contextMenuState.visible && (
              <div
                ref={contextMenuRef}
                style={{
                  position: "absolute",
                  top: contextMenuState.y,
                  left: contextMenuState.x,
                }}
                className="context-menu-container"
              >
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => setInfoDisplay("block")}>
                      Edit Details
                    </p>
                  </div>
                </div>
                <hr className="hr" />
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => handleDelete(event)}>
                      Delete Details
                    </p>
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      {authStatus && <h4 className="error error-position-info">{authStatus}</h4>}

      <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
        Download CSV
      </button>
      <div className="edit-info-container-1" style={{ display: infoDisplay }} onDoubleClick={(event: any) => setInfoDisplay("none")}>
        <ShowInfo url={"edit-new-issue"} newIssues={infoEdit} setDisplay={setInfoDisplay} />
      </div>
    </div>
  );
}
export default NewIssues;
