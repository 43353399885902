import { CloseIcon } from "../../../common/functions";
import { dataBrokersLabels } from "./data";

function MatchedVcon(props: any) {
  let editTableTitles = [...props.editTableTitles];
  editTableTitles.pop();
  // Helper function to split array into chunks of specified size
  const chunkArray = (array: any[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const titleChunks = chunkArray(editTableTitles, 3);

  if (!props.trade["BB Ticker"]) {
    return <div></div>;
  }

  return (
    <div className="position-container edit-info-container-2">
      <div onClick={(event) => props.setMatchVconDisplay("none")} className="close-icon-container-edit-position">
        <CloseIcon />
      </div>
      <p>The matched vcon details</p>
      <form
        style={{
          overflowX: "auto",
        }}
      >
        <table id="table-id" className="table table-hover table-portfolio table-trades table-edit">
          <tbody>
            {titleChunks.map((chunk, indexChunk) => (
              <tr key={indexChunk}>
                {chunk.map((title, index) => (
                  <>
                    <td style={{ backgroundColor: props.trade[title + " Color"] }}>{title}</td>
                    <td style={{ backgroundColor: props.trade[title + " Color"], cursor: props.trade[title + " Color"] ? "pointer" : "", fontWeight: props.trade[title + " Color"] ? "bold" : "", textDecoration: props.trade[title + " Color"] ? "underline" : "", fontStyle: props.trade[title + " Color"] ? "italic" : "", color: props.trade[title + " Color"] ? "blue" : "" }} onDoubleClick={(event: any) => (props.trade[title + " Color"] ? props.editMatchVcon(event, title, props.trade[title]) : "")}>
                      <p style={{ textDecoration: "line-through" }}>{props.trade[title + " Color"] ? "FO Trade: " + props.foTrade[title] : ""}</p>
                      {props.trade[title + " Color"] ? "VCON Trade: " + props.trade[title] + " (Double Click here to modify your trade)" : props.trade[title]}
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default MatchedVcon;
